import ImageClient from './ImageClient';
import FisheyeImageServer from './FisheyeImageServer';
import UsualImageServer from './UsualImageServer';
import PIPImageServer from './PIPImageServer';
// XXX: The worker's file name is such strange;
// because in dev mode, webpack will cache the worker content
// unless you change the file name.
// if you'd changed something behind the worker
// i.e., worker imports server.js, you modified the server.js,
// you will need to rename the worker file by git mv
// and the filename here. Don't need to rename server.js
// Not sure about production case though.
import workerClass from './test.27.worker';
import TaskManager from './TaskManager';
import MouseEventPreprocessor from './EventPreprocessor';
import SERVER from './constants/server';

const SERVER_MAP = {
  0: UsualImageServer,
  1: FisheyeImageServer,
  2: PIPImageServer,
};

class ThreadableCanvas {
  /**
   * Create a threadable canvas; which includes a image client
   * and specific image server from the config.
   * During setup of this, we will decide to use single thread
   * or multi-thread to draw the canvas(which relies on Offscreen Canvas API).
   *
   * This module is composed by some sub modules
   * * TaskManager - uses RAF or IdleCallback as the task trigger; be responsible to
   *   run the given task at a smooth timing.
   * * ImageClient - be responsible to bypass command/config from ThreadableCanvas to
   *   the image server. It will create the canvas on demand in main thread or off-thread.
   * * ResizeObserver - see https://caniuse.com/resizeobserver; this observers what
   *   the image client generated element and callback resize to image client;
   *   we can also let image client do this though.
   *
   * @example new ThreadableCanvas({ server: ThreadableCanvas.SERVER_TYPE.PIP });
   * @param { Object } param - options of threadable-canvas
   * @param { HTMLVideoElement } param.source - the basic input media element, can be empty.
   * @param { HTMLCanvasElement } param.destination
   * - given canvas element so we don't need to generate a new one, could be empty.
   * @param { Number } param.server - indicate the image server type index
   * @param { Function } param.joystick - an api call to device's joystick API if supported
   * @param { Object } param.ssmuxInstance
   * - given ssmux instance to avoid being instantiated again if not multi-thread env
   */
  constructor({
    server = 0,
    source = null,
    destination = null,
    ssmuxInstance = null,
    lazyMode = false,
    stretch = false,
    forceRenderIfPaused = true
  } = {}) {
    const serverClass = SERVER_MAP[server];
    this.imageClient = new ImageClient({
      source,
      destination,
      Main: serverClass,
      Worker: workerClass,
      serverType: server,
      forceRenderIfPaused,
      // For PIP image server we don't want to create a new thread for it;
      // so it's always in main thread.
      // XXX: now ssmux(emscripten module instantiation) is too slow
      // to init in certain environment,
      // even the navigator.hardwareConcurrency is more than 4.
      // so we need to init fisheye image server in main thread.
      // if one day we need to enable multi-viewcell dewarp,
      // the performance issue of ssmux need to be re-evaluated.
      useOffscreenIfPossible: server === ThreadableCanvas.SERVER_TYPE.USUAL && ThreadableCanvas.useImageBitMap,
      preprocessor: server === ThreadableCanvas.SERVER_TYPE.USUAL ? new MouseEventPreprocessor() : null,
    }, ssmuxInstance);
    this.element = this.imageClient.destination;
    this.source = source;
    if (this.source) {
      this.source.addEventListener('play', this);
    }
    const that = this;
    this.hasTargetSize = false;
    this.resizeObs = new ResizeObserver(() => {
      if (that.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      // resize only after dragging ended
      that.resizeTimeout = setTimeout(() => {
        that.resizeTimeout = null;
        that.imageClient.resize(that.element.clientWidth, that.element.clientHeight);
        this.hasTargetSize = true;
      }, 50);
    });
    this.resizeObs.observe(this.element);
    this.taskManager = new TaskManager(lazyMode, this.source);
    if (source) {
      this._handle_play();
    }
    this.imageClient.config('stretch', stretch);
    this.fps = 0;
  }

  async render() {
    if (!this.hasTargetSize) {
      this._handle_play();
      return Promise.reject();
    }
    if (this.source && this.source.videoWidth === 0) {
      this._handle_play();
      return Promise.reject();
    }

    // calculate fps
    // the reason why we comment it is because
    // 1. it blocks main thread
    // 2. performance.now is slower than Date.now in Google Chrome;
    // for test reason you can uncomment it.
    /*
      let delta = (performance.now() - lastRun) / 1000;
      lastRun = performance.now();
      this.fps = (1 / delta).toFixed(2);
    */

    if (this.source) {
      if (!ThreadableCanvas.useImageBitMap) {
        this.imageClient.render(
          this.source,
          this.source.videoWidth,
          this.source.videoHeight
        );
        this._handle_play();
        return Promise.resolve();
      }
      return createImageBitmap(
        this.source, 0, 0, this.source.videoWidth, this.source.videoHeight
      ).then((imageBitmap) => {
        this.imageClient.render(
          imageBitmap,
          this.source.videoWidth,
          this.source.videoHeight
        );
        this._handle_play();
      }).catch(() => {
        this.imageClient.render(null, 0, 0);
        this._handle_play();
      });
    }
    this.imageClient.render(null, 0, 0);
    this._handle_play();
    return Promise.resolve();
  }

  config(key, value) {
    this.imageClient.config(key, value);
    // by returning this, we can continuously do config.
    return this;
  }

  command(command, ...args) {
    this.imageClient[command](...args);
  }

  handleEvent(evt) {
    if (this[`_handle_${evt.type}`]) {
      this[`_handle_${evt.type}`](evt);
    }
  }

  _handle_play() {
    const render = () => {
      this.render().catch(() => {});
    };
    this.taskManager.queue(render.bind(this));
  }

  destroy() {
    if (this.source) {
      this.source.removeEventListener('play', this);
      delete this.source;
    }
    this.taskManager.stop();
    this.taskManager.destroy();
    delete this.taskManager;
    this.imageClient.destroy();
    delete this.imageClient;
    this.resizeObs.disconnect();
    this.resizeObs = null;
    delete this.element;
  }
}

ThreadableCanvas.SERVER_LIST = SERVER.SERVER_LIST;
ThreadableCanvas.SERVER_TYPE = SERVER.SERVER_TYPE;

/**
 * Currently, use imageBitMap will cost us extra computing power,
 * and trigger unwanted blink while video source resolution changes.
 */
ThreadableCanvas.useImageBitMap = false;

export default ThreadableCanvas;
