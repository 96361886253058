/**
 * Utility module for RTSP request
 * @module RtspRequest
 */

import { getTagged, Logger } from '@vivotek/lib-utility/logger';
import { str2ab } from '@vivotek/lib-utility/convert_arraybuffer_string';
import METHODS from '../constants/method';

const Log = getTagged('rtsp-protocol', Logger.DEBUG);

/**
 * Base class for RTSP request
 *
 * RtspRequest is inspired from axios and rtsp-parser-js.
 * To sync with axios.get/axios.post,
 * RtspRequest is using `new RtspRequest.play()` to generate a play request
 * and vice versa.
 *
 * @memberof module:RtspRequest
 * @inner
 */
class BaseRtspRequest {
  /**
   * Create a new instance of RTSP request
   * @param {Object} params - Parameters for the request
   * @param {string} params.url - The RTSP server URL
   * @param {Object.<string, string>} params.headers - The headers of the request
   * @param {ArrayBuffer|string} [params.body] - The body of the request
   */
  constructor({
    url,
    headers,
    body,
  }) {
    this.url = url;
    this.id = headers.CSeq;
    this.headers = headers;
    this.body = body;
  }

  /**
   * Convert the RTSP request to an ArrayBuffer
   * @returns {ArrayBuffer} The ArrayBuffer containing the RTSP request
   */
  toRequest() {
    return str2ab(`${this.toString()}\r\n\r\n`);
  }

  /**
   * Convert the RTSP request to a string
   * @returns {string} The RTSP request string
   */
  toString() {
    return [
      `${this.method} ${this.url} RTSP/1.0`,
    ].concat(Array.from(Object.entries(this.headers)).sort((a, b) => {
      if (a[0] === 'CSeq') {
        return -1;
      }
      return 1;
    }).map(([k, v]) => `${k}: ${v}`)).join('\r\n');
  }

  /**
   * Send the RTSP request through a channel
   * @param {Object} channel - The channel to send the request through
   * @returns {Promise} A promise that resolves when the response is received
   */
  send(channel) {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      try {
        channel.send(this.toRequest());
        Log.debug(`C->S:\n\n${this.toString()}`);
      } catch (err) {
        reject(err);
      }
    });
  }
}

/**
 * RTSP request factory
 * @memberof module:RtspRequest
 * @inner
 * @type {Object}
 */
const RtspRequest = {};

Array.from(Object.entries(METHODS)).forEach(([command]) => {
  const cls = class extends BaseRtspRequest {};
  cls.prototype.method = command;
  RtspRequest[command.toLowerCase()] = cls;
});

export default RtspRequest;
