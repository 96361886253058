import { v4 as uuidv4 } from 'uuid';

const protocol = (window.location.protocol === 'https:' ? 'wss:' : 'ws:');
const NVR_WEBSOCKET_URI = `${protocol}/${window.location.host}/fcgi-bin/plgserver.cgi`;
const REQUIRE_AUTH = '{"cmd":"ReqAuth","data":"[\\"\\",\\"\\",\\"basic\\"]","msgType":0,"serial":0}';

const getPluginId = (type) => {
  switch (type) {
    case 'CameraPropertiesChanged':
    case 'CameraIOChanged':
    case 'CameraInformation':
      return 'EEA8575A54FB0D315CF64CB2B8D93504';

    case 'RecordingStatusChanged':
      return 'A1B3C2E89AB12CE3A0B1CBE5A5904DE1';

    case 'getRecordingVCARule':
    case 'getRecordingVCAProject':
      return '4a6c7db2eaf84341b0a1e30507161c73';

    case 'QueryEventList':
    case 'QueryTrendMicroEventList':
      return '2F4484ECD91046658F567F2B3B0FDAFD';

    case 'SmartMotionSearch':
    case 'GetHumanDetectionJson':
    case 'StopSmartMotionSearch':
      return '8282c503205c4987925c1e46459f846a';

    default:
      return '05CC407A5D33E972EAD72F3E6E55734F';
  }
};

const getBlockRequest = (type) => {
  switch (type) {
    case 'CameraPropertiesChanged':
    case 'CameraIOChanged':
    case 'CameraInformation':
    case 'RecordingStatusChanged':
      return true;

    case 'getRecordingVCARule':
    case 'getRecordingVCAProject':
    case 'SmartMotionSearch':
    case 'GetHumanDetectionJson':
    case 'StopSmartMotionSearch':
    case 'GetNotification':
    case 'MarkNotificationAsRead':
    case 'QueryEventList':
    case 'QueryTrendMicroEventList':
      return false;

    default:
      return true;
  }
};

const getSubscribeRequest = (type, state, uuid = '') => JSON.stringify({
  cmd: 'PluginSubscribeEventBlock',
  data: JSON.stringify({
    EventType: type,
    PluginID: getPluginId(type),
    SID: ''
  }),
  msgType: 1,
  serial: state.serial,
  uuid
});

const getQueryRequest = (type, state, requestInfo = {}, uuid = '') => JSON.stringify({
  cmd: 'PluginRequest',
  data: JSON.stringify({
    BlockRequest: getBlockRequest(type),
    RequestName: type,
    PluginID: getPluginId(type),
    RequestInfo: requestInfo,
  }),
  msgType: 1,
  serial: state.serial,
  uuid,
});

const parseEncoders = (info) => {
  const encoders = {};
  Object.keys(info).forEach((key) => {
    const index = key.slice(8); // key is a string with prefix: '/Encoder' + index
    encoders[index] = info[key];
  });
  return encoders;
};

const parseHumanDetectionArea = (info = {}) => {
  let project = null;
  try {
    project = JSON.parse(info['FAEBAE54-DBE8-4243-99AC-31559D0567D0_SingleValue_String']);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return project;
};

const awaitMsgs = {};

const actions = {
  fetch({
    state, dispatch, commit, rootGetters
  }) {
    if (state.websocket) {
      return;
    }

    const websocket = new WebSocket(NVR_WEBSOCKET_URI);
    let interval = -1;
    websocket.onopen = () => {
      const timeout = 30000;
      // heart beat
      interval = setInterval(() => {
        if (state.websocket.readyState === state.websocket.OPEN) {
          state.websocket.send(' ');
        }
      }, timeout);
      commit('updateWebsocket', { websocket, interval });
    };
    websocket.onclose = () => {
      window.clearInterval(state.interval);
    };
    websocket.onmessage = (evt) => {
      const data = JSON.parse(evt.data.slice(1, -1));
      let type = '';
      if (data.cmd === 'ReqAuth') {
        dispatch('requireAuthentication');
        dispatch('query', 'BuzzerStart');
        dispatch('query', 'GetStorageStatus');
        dispatch('query', 'GetNotification');
        dispatch('subscribe', 'AlarmTrigger');
        dispatch('subscribe', 'BuzzerOn');
        dispatch('subscribe', 'CameraPropertiesChanged');
        dispatch('subscribe', 'CameraIOChanged');
        dispatch('subscribe', 'RecordingStatusChanged');
        dispatch('subscribe', 'StorageStatusChanged');
        // query 'CameraInformation' after subscribe 'CameraPropertiesChanged' to keep the data is newest
        dispatch('query', 'CameraInformation');
      } else if (data.cmd === 'PluginEvents') {
        const event = JSON.parse(data.data).Event;
        const eventExtraInfo = JSON.parse(event.EventExtraInfo);
        type = event.EventType;
        let encoders = {};
        switch (type) {
          case 'AlarmTrigger':
            commit('notification/addNewNotification', {
              notification: eventExtraInfo,
              timezone: rootGetters['system/systemTimezone'],
              encoders: rootGetters['encoder/availableEncoders']
            }, { root: true });
            break;
          case 'BuzzerOn':
            commit('updateBuzzer', eventExtraInfo.on);
            break;
          case 'CameraPropertiesChanged':
            encoders = parseEncoders(eventExtraInfo);
            commit('encoder/setEncoderByProps', encoders, { root: true });

            Object.keys(encoders).filter((index) => encoders[index]?.status === 'CAM_ACTIVE').forEach((index) => {
              dispatch('encoder/fetchEncoderByIndex', index, { root: true });
              dispatch('encoder/fetchSettingByIndex', index, { root: true });
            });
            break;
          case 'CameraIOChanged':
            commit('encoder/setDIDOStatusByProps', eventExtraInfo, { root: true });
            break;
          case 'RecordingStatusChanged':
            commit('encoder/setRecordingStatusByProps', eventExtraInfo, { root: true });
            break;
          case 'StorageStatusChanged':
            commit('updateStorageStatus', eventExtraInfo);
            break;

          default:
            break;
        }
      } else if (data.cmd === 'PluginRequest') {
        const res = JSON.parse(data.data);
        type = res.RequestName;
        let info;
        const isFinal = data.reqRespFin;

        // The server-side send object without RequestResInfo
        if (type !== 'StopSmartMotionSearch') {
          info = JSON.parse(res.RequestResInfo);
        }

        switch (type) {
          case 'BuzzerStart':
            commit('updateBuzzer', info.BuzzerStart);
            break;
          case 'GetNotification':
            commit('notification/updateNotifications', {
              notifications: info,
              timezone: rootGetters['system/systemTimezone'],
              encoders: rootGetters['encoder/availableEncoders']
            }, { root: true });
            break;
          case 'GetStorageStatus':
            commit('updateStorageStatus', info);
            break;
          case 'CameraInformation':
            commit('encoder/setEncoderByProps', parseEncoders(info), { root: true });
            dispatch('encoder/fetchSetting', {}, { root: true });
            break;
          case 'GetHumanDetectionJson':
            commit('ai/updatePeopleDetectionArea', parseHumanDetectionArea(info), { root: true });
            break;
          case 'SmartMotionSearch':
            if (rootGetters['ai/status'] !== 'loading') {
              // if result.length > 200 or do "stopSmartSearchII" , stopped to commit data to result
              break;
            }

            dispatch('ai/setSmartMotionSearchResult', { data: info, isFinal, encoders: rootGetters['encoder/smartSearchEncoders'] }, { root: true });
            break;
          case 'StopSmartMotionSearch':
            break;
          case 'MarkNotificationAsRead': // response
            commit('notification/setNotificationAsRead', info.notificationID, { root: true });
            break;

          default:
            if (awaitMsgs[data.uuid]?.resolve) {
              awaitMsgs[data.uuid].resolve(info);
            }
            break;
        }
      }
    };
    websocket.onerror = (evt) => {
    };
  },
  requireAuthentication({ state, commit }) {
    state.websocket.send(REQUIRE_AUTH);
    commit('updateSerial');
  },
  query({ state, commit }, type) {
    const req = getQueryRequest(type, state);
    state.websocket.send(req);
    commit('updateSerial');
  },
  send({ state, commit }, { message, uuid }) {
    if (!state.websocket) {
      return Promise.reject(new Error('WebSocket Not Connect'));
    }

    return new Promise((resolve, reject) => {
      awaitMsgs[uuid] = {
        timeout: setTimeout(() => {
          delete awaitMsgs[uuid];
          reject(new Error('Request Timeout'));
        }, 60 * 1000),
        resolve(res) {
          clearTimeout(this.timeout);
          delete awaitMsgs[uuid];
          resolve(res);
        },
      };

      state.websocket.send(message);
      commit('updateSerial');
    });
  },
  subscribe({ state, commit }, type) {
    const req = getSubscribeRequest(type, state);
    state.websocket.send(req);
    commit('updateSerial');
  },
  querySmartSearchII({ state, commit }, params) {
    const {
      searchKey, startTime, endTime,
      duration, interval,
      locationID, sensitivity, activeCells,
      peopleDetection, polygon
    } = params;

    const requestInfo = JSON.stringify({
      SearchKey: searchKey,
      StartTimestamp: startTime,
      EndTimestamp: endTime,
      LocationID: locationID,
      LocalMountPath: '',
      MinActivityDuration: duration,
      ActivityMergeInterval: interval,
      Sensitivity: sensitivity,
      ActiveCells: activeCells,
      ActiveCellsRow: 64,
      ActiveCellsColumn: 64,
      IsHumanDetection: peopleDetection,
      NoramlizedPolygon: polygon,
    });

    const req = getQueryRequest('SmartMotionSearch', state, requestInfo);
    state.websocket.send(req);
    commit('updateSerial');
  },
  queryHumanDetectionArea({ state, commit }, params) {
    const requestInfo = JSON.stringify(params);
    const req = getQueryRequest('GetHumanDetectionJson', state, requestInfo);
    state.websocket.send(req);
    commit('updateSerial');
  },
  queryPlaybackVCARule({ state, dispatch }, params) {
    const requestInfo = JSON.stringify(params);
    const uuid = uuidv4();
    const message = getQueryRequest('getRecordingVCARule', state, requestInfo, uuid);

    return dispatch('send', { message, uuid });
  },
  queryPlaybackVCAProject({ state, dispatch }, params) {
    const requestInfo = JSON.stringify(params);
    const uuid = uuidv4();
    const message = getQueryRequest('getRecordingVCAProject', state, requestInfo, uuid);

    return dispatch('send', { message, uuid });
  },
  queryEventList({ state, dispatch }, params) {
    const requestInfo = JSON.stringify(params);
    const uuid = uuidv4();
    const message = getQueryRequest('QueryEventList', state, requestInfo, uuid);

    return dispatch('send', { message, uuid });
  },
  queryTrendMicroEventList({ state, dispatch }, params) {
    const requestInfo = JSON.stringify(params);
    const uuid = uuidv4();
    const message = getQueryRequest('QueryTrendMicroEventList', state, requestInfo, uuid);

    return dispatch('send', { message, uuid });
  },
  stopSmartSearchII({ state, commit }, params) {
    const requestInfo = JSON.stringify({
      SearchKey: params.searchKey,
    });
    const req = getQueryRequest('StopSmartMotionSearch', state, requestInfo);
    state.websocket.send(req);
    commit('updateSerial');
  },
  sendNotificationAsRead({ state, commit }, id) {
    if (id === -1) {
      return;
    }

    const requestInfo = JSON.stringify({
      notificationID: id
    });
    const req = getQueryRequest('MarkNotificationAsRead', state, requestInfo);
    state.websocket.send(req);
    commit('updateSerial');
  }
};

export default actions;
