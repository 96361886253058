import DateFormatter from './formatDate';
/* eslint-disable no-underscore-dangle */
const browserTzoffs = new Date().getTimezoneOffset() * 60;
/*
  Prevent the side effect of browser's timezone offset,
  We only use Date's method "getUTCxxx" to display local time.
  In this file, "long time format" in `localTimestamp` is only used to display local time.
*/
export default class LocalTime {
  constructor(options) {
    this.HOUR_12_BASE = false;
    if (typeof options === 'string') {
      this.timeStr = options;

      const timeFormat = /^(\d{4})-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)$/;
      const projectionTimeStrToUTC = (str) => {
        const parts = timeFormat.exec(str);
        return Date.UTC(parts[1], parseInt(parts[2], 10) - 1, parts[3],
          parts[4], parts[5], parts[6], 0);
      };

      this.$localTimestamp = projectionTimeStrToUTC(options);
    } else if (typeof options === 'number') {
      this.$timestamp = options;
      this.$tzoffs = 0;
    } else {
      const { timestamp, tzoffs, isMeridian = this.HOUR_12_BASE } = options;

      this.$timestamp = timestamp;
      this.$tzoffs = tzoffs;
      this.HOUR_12_BASE = isMeridian;
      this.updateLocalTimestamp();
    }
  }

  useClientTimezone() {
    this.$tzoffs = browserTzoffs;
    this.updateLocalTimestamp();
    return this;
  }

  useUniTimezone() {
    this.$tzoffs = 0;
    this.updateLocalTimestamp();
    return this;
  }

  updateLocalTimestamp() {
    this.$localTimestamp = this.$timestamp - this.$tzoffs * 1000;
  }

  static getBrowserTimezoneOffset() {
    return browserTzoffs;
  }

  get fullYear() {
    return this._getDate().getUTCFullYear();
  }

  get month() {
    return this._getDate().getUTCMonth(); // 0 ~ 11
  }

  get date() {
    return this._getDate().getUTCDate();
  }

  get hours() {
    return this._getDate().getUTCHours();
  }

  get minutes() {
    return this._getDate().getUTCMinutes();
  }

  get seconds() {
    return this._getDate().getUTCSeconds();
  }

  get queryDate() {
    return `${this.fullYear}-${`${this.month + 1}`.padStart(2, '0')}-${(`${this.date}`).padStart(2, '0')}`;
  }

  get queryTime() {
    return `${`${this.hours}`.padStart(2, '0')}:${`${this.minutes}`.padStart(2, '0')}:${`${this.seconds}`.padStart(2, '0')}`;
  }

  static roundUpToNearestTen(timestamp) {
    const date = new Date(timestamp);
    const minutes = date.getMinutes();
    const minutesToRoundUp = 10 - (minutes % 10);
    if (minutes + minutesToRoundUp >= 60) {
      date.setHours(date.getHours() + 1);
    }
    date.setMinutes((minutes + minutesToRoundUp) % 60);
    date.setSeconds(0);
    return date;
  }

  static forceSecondTo(time, number) {
    const [hours, minutes, _] = time.split(':'); // eslint-disable-line no-unused-vars
    return `${hours}:${minutes}:${`${number}`.padStart(2, '0')}`;
  }

  get displayClientFullTime() {
    if (!this.$timestamp) {
      return '';
    }
    const t = this.$timestamp / 1000;
    return `${DateFormatter.localeDate(t)} ${DateFormatter.localeTime(t)}`;
  }

  get displayFullTime() {
    if (!this.$localTimestamp) {
      return '';
    }
    return `${this.displayDate} ${this.displayTime}`;
  }

  get displayDate() {
    if (!this.$localTimestamp) {
      return '';
    }
    return `${DateFormatter.localeUTCDate(this.$localTimestamp / 1000)}`;
  }

  get displayTime() {
    if (!this.$localTimestamp) {
      return '';
    }
    return `${DateFormatter.localeUTCTime(this.$localTimestamp / 1000)}`;
  }

  get localTimestamp() {
    return this.$localTimestamp;
  }

  get timestamp() {
    return this.$timestamp;
  }

  get tzoffs() {
    return this.$tzoffs;
  }

  set timestamp(value) {
    this.$timestamp = value;
    this.updateLocalTimestamp();
  }

  set tzoffs(value) {
    this.$tzoffs = value;
    this.updateLocalTimestamp();
  }

  _getDate() {
    return new Date(this.$localTimestamp);
  }

  valueOf() {
    return this.$localTimestamp;
  }

  getTime() {
    return this.$localTimestamp;
  }

  toString() {
    return `${this.queryDate}T${this.queryTime}`;
  }

  forwardMilliseconds(msec) {
    const newTime = this.projection();

    newTime.timestamp += msec;

    return new LocalTime(newTime);
  }

  projection(tzoffs) {
    // eslint-disable-next-line no-param-reassign
    tzoffs = tzoffs ?? this.$tzoffs ?? browserTzoffs;

    return new LocalTime({
      timestamp: this.$localTimestamp + tzoffs * 1000, tzoffs
    });
  }
}
