<template>
  <div
    class="pluginless"
    :id="`viewcell-${channel - 1}`"
  >
    <div
      :class="{ stretch }"
      class="video_wrapper"
      ref="videoWrapper"
    />
    <div
      class="canvas_wrapper"
      :class="{ stretch }"
      v-if="!showWithLiteMode"
      ref="canvasWrapper"
      @mousedown="handleCanvasWrapperMousedown"
      @mousemove="handleCanvasWrapperMousemove"
      @click="handleCanvasWrapperClick"
    />
  </div>
</template>

<script>
import {
  Liveview,
} from '@vivotek/lib-medama';
import ThreadableCanvas from '@vivotek/threadable-canvas';
import { v1 as RtspProtocol } from '@vivotek/lib-rtsp-protocol';
import PLUGINFREE from '@/constants/pluginfree';

export default {
  name: 'PluginlessWrapper',
  props: {
    camera: {
      type: Object,
      default: () => {},
    },
    viewinfo: {
      type: Object,
      default: () => {},
    },
    liteMode: {
      type: Boolean,
      default: false
    },
    channel: {
      type: Number,
      default: 1,
    },
    stream: {
      type: Number,
      default: 0,
    },
    stretch: {
      type: Boolean,
      default: true,
    },
    mountType: {
      type: String,
      default: 'wall', // 'wall', 'ceiling', 'floor'
    },
    dewarpType: {
      type: String,
      default: '1O', // '1O', '1R', '1P'
    },
    dewarpPreset: {
      type: String,
      default: '',
    },
    volume: {
      type: Number,
      default: 0, // 0 ~ 100
    },
    mute: {
      type: Boolean,
      default: true,
    },
    username: {
      type: String,
      default: '',
    },
    sessionId: {
      type: String,
      default: '',
    },
    isActiveView: {
      type: Boolean,
      default: false,
    },
    showPeopleDetectionArea: {
      type: Boolean,
      default: true,
    },
    showExclusiveArea: {
      type: Boolean,
      default: true,
    },
    joystick: {
      type: Object,
      default: null,
    }
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    this.setMute(); // force to mute when destroy

    this.stop();
    this.unbindViewcellEventProcess();
    this.removeImage();
    this.closeRtspChannel();
    this.removeResizeObserver();
  },
  computed: {
    pipInfo() {
      return this.viewinfo.pipInfo;
    },
    rule() {
      return (this.showExclusiveArea ? this.viewinfo.camera.rule : this.rulesWithoutExclusiveArea);
    },
    wsSrc() {
      const pcol = (window.location.protocol === 'https:' ? 'wss:' : 'ws:');
      const fullPath = `${pcol}//${window.location.host}/ws/module_over_ws`;
      return fullPath;
    },
    url() {
      return `rtsp://localhost/Media/Live/Normal?camera=C_${this.channel}&streamtype=${this.streamType}`;
    },
    streamType() {
      return this.stream ? 'sub' : 'main';
    },
    showWithLiteMode() {
      return this.liteMode && !this.isActiveView;
    },
    hasImage() {
      return this.image;
    },
    hasInstance() {
      return this.viewcell instanceof Liveview;
    },
    enableDewarp() {
      return this.fisheyeIsValid && !this.isLocalDewarped;
    },
    cameraTimeSec() {
      return Math.floor(this.cameraTime / 1000);
    },
    workerSrc() {
      return PLUGINFREE.WORKER_265_SRC;
    },
    isYUVCanvas() {
      if (!this.viewcell) {
        return false;
      }

      const player = this.viewcell.getPlayer();

      return player?.nodeName === 'CANVAS';
    },
    isIdle() {
      const { status } = this.viewinfo;

      return status !== 'PLAYING' && status !== 'LOADING';
    },
    enableVCA() {
      return this.viewinfo.options.display?.vca === '1' && this.viewinfo.shouldDisplayVCAIcon;
    },
    rulesWithoutExclusiveArea() {
      const filteredRules = Object.keys(this.viewinfo.camera.rule)
        .filter((key) => key !== 'ExclusiveArea')
        .reduce((obj, key) => ({
          ...obj,
          [key]: this.viewinfo.camera.rule[key]
        }), {});
      return filteredRules;
    }
  },
  data() {
    return {
      cameraTime: 0,
      cameraTzoffs: 0,
      viewcell: null,
      isLocalDewarped: false,
      fisheyeIsValid: false,
      rtspChannel: null,
      retryConnect: null,
      image: null,
      mousemoved: false,
      loopTicket: null,
      stopByVisibilityChangeTimeout: null,
      resizeTimeout: null,
      jsonRules: '',
    };
  },
  watch: {
    isActiveView(val) {
      if (val) {
        this.$emit('enableDewarp', {
          isValid: this.fisheyeIsValid,
          isLocalDewarped: this.isLocalDewarped,
        });
      }
    },
    url() {
      this.replay();
    },
    stretch(val) {
      this.setStretch(val);
    },
    dewarpType(val) {
      this.setDewarpType(val);
    },
    volume(val) {
      this.setVolume(val);
    },
    mute(val) {
      this.setMute(val);
    },
    showWithLiteMode(val) {
      if (val) {
        this.removeImage();

        if (this.viewcell && this.isYUVCanvas) {
          const player = this.viewcell.getPlayer();

          this.appendPlayerToWrapper(player);
        }
      } else {
        if (this.viewinfo.status !== 'PLAYING') {
          return;
        }
        this.createImage().catch(() => {
          // eslint-disable-next-line no-console
          console.warn(`camera ${this.channel} video is not ready`);
        });
      }
    },
    pipInfo: {
      deep: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.moveWindowPOS(val);
      },
    },
    'camera.rule': {
      deep: true,
      handler(val) {
        if (!val) {
          return;
        }
        if (this.image) {
          this.image.config('rule', this.rule);
        }
      },
    },
    'camera.projection': {
      deep: true,
      handler(val) {
        if (!val) {
          return;
        }
        if (!this.image) {
          return;
        }
        if (this.camera.projection) {
          this.image.config('projection', this.camera.projection);
        }
      },
    },
    enableDewarp(val) {
      if (this.isActiveView) {
        this.$emit('enableDewarp', {
          isValid: this.fisheyeIsValid,
          isLocalDewarped: this.isLocalDewarped,
        });
      }
    },
    cameraTimeSec() {
      this.handleTimestamp(this.cameraTime);
    },
    enableVCA(val) {
      if (!this.hasInstance || !this.hasImage) {
        return;
      }

      this.image.config('showVCA', val);
    },
    showPeopleDetectionArea(val) {
      if (!this.image) {
        return;
      }

      const humanDetectionArea = val ? this.viewinfo.peopleDetectionArea : null;

      this.image.config('humanDetectionArea', humanDetectionArea);
    },
  },
  methods: {
    setDewarpType(dewarpType) {
      if (!this.hasImage) {
        return;
      }

      const luteinDisplayMode = PLUGINFREE.DEWARP[dewarpType] || 0;
      this.image.config('presentMode', luteinDisplayMode);
    },

    setMountType(mountType) {
      if (!this.hasImage) {
        return;
      }

      const luteinDisplayMode = PLUGINFREE.MOUNTTYPE[mountType] || 0;

      this.image.config('mountType', luteinDisplayMode);
    },

    setStretch(stretch) {
      if (!this.hasImage) {
        return;
      }
      this.image.config('stretch', stretch);
    },

    setVolume(vol) {
      if (!this.hasInstance) {
        return;
      }
      this.viewcell.setVolume(vol / 100);
    },

    setMute(val = true) {
      if (!this.hasInstance) {
        return;
      }
      if (val) {
        this.viewcell.setMute();
      } else {
        this.viewcell.unmute();
      }
    },

    pause() {},

    snapshot() {
      if (this.image && this.image.snapshot) {
        this.image.command('snapshot');
      } else {
        const canvas = document.createElement('canvas');
        const g = canvas.getContext('2d');
        const player = this.viewcell.getPlayer();
        canvas.width = player.videoWidth;
        canvas.height = player.videoHeight;
        g.drawImage(player, 0, 0, player.videoWidth, player.videoHeight);
        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = 'screenshot.png';
        a.click();
      }
    },

    play() {
      if (document.hidden) {
        return Promise.reject();
      }
      this.clearStopByVisibilityChange();
      this.viewinfo.status = 'LOADING';
      return this.tryToPlayLooped();
    },

    stop() {
      this.clearRetryConnect();
      if (!this.viewcell || this.viewinfo.status === 'STOPPING' || this.viewinfo.isEquivalentToStopped) {
        this.closeRtspChannel();
        return Promise.resolve();
      }
      // We are faking seek action by stop + play so we cannot enter stopping here.
      if (this.viewinfo.status !== 'SEEKING') {
        this.viewinfo.status = 'STOPPING';
      }

      return this.viewcell.stop()
        .finally(() => {
          this.cameraTime = 0;
          this.cameraTzoffs = 0;
          this.removeViewcell();
        });
    },

    replay() {
      this.stop()
        .then(() => {
          this.unbindViewcellEventProcess();
          this.play();
        });
    },

    moveWindowPOS(pip) {
      if (!this.hasImage) {
        return;
      }

      this.image.config('pip', pip);
    },
    /* internal methods */
    tryToPlay() {
      this.viewinfo.status = 'LOADING';
      return this.createViewCell()
        .then((viewcell) => {
          viewcell.play();
        });
    },

    tryToPlayLooped() {
      this.loopTicket = Math.random().toString(36).substr(-6);

      const { loopTicket } = this;
      return new Promise((resolve) => {
        this.tryToPlay().then(resolve).catch((err) => {
          // eslint-disable-next-line no-console
          console.warn('play failed: ', err);

          if (loopTicket !== this.loopTicket) {
            resolve();
            return;
          }
          // retry to play again after 5 sec
          this.removeViewcell();
          this.closeRtspChannel();
          this.retryConnect = setTimeout(() => resolve(this.tryToPlayLooped()), 5 * 1000);
        });
      });
    },

    clearRetryConnect() {
      if (this.retryConnect) {
        clearTimeout(this.retryConnect);
      }

      this.retryConnect = null;
    },

    createViewCell() {
      return this.getRtspChannel()
        .then((rtspChannel) => {
          this.viewcell = new Liveview({
            rtspChannel,
            url: this.url,
            username: this.username,
            sessionId: this.sessionId,
            workerLibde265Path: this.workerSrc,
          });
          this.viewcell.SOURCEBUFFER_KEEP_LENGTH = 90;
          this.bindViewcellEventProcess();
          this.setVolume(this.volume);
          this.setMute(this.mute);
          return this.viewcell;
        });
    },

    removeViewcell() {
      if (!this.viewcell) {
        return;
      }

      this.removePlayerFromWrapper();
      this.unbindViewcellEventProcess();
      this.viewcell.destroy();
      this.viewcell = null;
    },

    appendPlayerToWrapper(player) {
      this.$refs.videoWrapper.appendChild(player);
    },

    appendImageToWrapper(image) {
      this.$refs.canvasWrapper.appendChild(image);
    },

    removePlayerFromWrapper() {
      const { videoWrapper } = this.$refs;
      while (videoWrapper && videoWrapper.firstChild) {
        videoWrapper.firstChild.remove();
      }
    },

    bindViewcellEventProcess() {
      if (!this.hasInstance) {
        return;
      }

      const { viewcell } = this;

      viewcell.on('play', this.handleViewcellPlay);
      viewcell.on('stop', this.handleViewcellStop);
      viewcell.on('notify', this.handleViewcellNotify);
      viewcell.on('error', this.handleViewcellError);
      viewcell.on('metj', this.handleMetjEvent);
    },

    unbindViewcellEventProcess() {
      if (!this.hasInstance) {
        return;
      }

      const { viewcell } = this;

      viewcell.off('play', this.handleViewcellPlay);
      viewcell.off('stop', this.handleViewcellStop);
      viewcell.off('notify', this.handleViewcellNotify);
      viewcell.off('error', this.handleViewcellError);
      viewcell.off('metj', this.handleMetjEvent);
    },

    handleViewcellPlay() {
      if (!this.showWithLiteMode && !this.image) {
        this.createImage();
      }

      const { isFisheye, fps } = this.viewcell;
      const player = this.viewcell.getPlayer();

      this.appendPlayerToWrapper(player);
      this.$emit('play', {
        player, isFisheye, frameRate: fps
      });
    },

    handleViewcellStop() {
      this.removeImage();
      this.$emit('stop');
    },

    handleViewcellNotify(notify) {
      this.handleCurrentTimeUpdate();

      if (notify.error) {
        this.$emit('error', notify.error);
        return;
      }

      if (this.viewinfo.status === 'PAUSED' && this.viewcell.playing) {
        this.viewinfo.status = 'PLAYING';
      }

      if (notify?.timestamp?.stream) {
        this.cameraTime = notify.timestamp.stream;
        this.cameraTzoffs = notify.timestamp.tzoffs;
      }
      // di event
      if (notify.di_status && notify.di_status.some((d) => !!d)) {
        this.$emit('di', notify.di_status);
      }
      // do event
      if (notify.do_status && notify.do_status.some((d) => !!d)) {
        this.$emit('do', notify.do_status);
      }

      if (notify.codec) {
        this.viewinfo.codec = notify.codec;
      }
      if (notify.resolution) {
        this.viewinfo.resolution.width = notify.resolution.width;
        this.viewinfo.resolution.height = notify.resolution.height;
      }
      // motion_window event
      if (notify.motion_window
          && notify.motion_window.length > 0
          && notify.motion_window.some((m) => m.setting && m.trigger)) {
        const cropWindow = {
          width: notify.capture_window.crop_width || notify.capture_window.width,
          height: notify.capture_window.crop_height || notify.capture_window.height
        };
        const motions = notify.motion_window.filter((m) => m.setting && m.trigger);

        this.handleViewcellMotion(motions, cropWindow);
      }
      if (notify.motion_window
          && notify.motion_window.length > 0
          && notify.motion_window.some((m) => m.setting)) {
        const mdAlert = Array.from({ length: 7 });

        notify.motion_window.forEach(({
          index, percentage, setting, trigger
        }) => {
          // index in motion_window is "1 base"
          mdAlert[index - 1] = { percentage, setting, trigger };
        });

        this.$emit('MotionDetectionAlert', mdAlert);
      }

      // fisheye event
      if (notify.fisheye) {
        this.handleViewcellFisheye(notify.fisheye, notify.resolution);
      }

      // audio capability
      if (this.viewcell.audioInited && !this.viewinfo.hasAudioCapability) {
        this.viewinfo.hasAudioCapability = this.viewcell.audioInited;
      }
    },

    handleViewcellMotion(motions = [], captureWindow = {}) {
      if (!this.image || !captureWindow.width || !captureWindow.height) {
        return;
      }

      const { image } = this;

      motions.forEach((motion) => {
        const polygon = [];

        if (motion.axis) {
          for (let i = 0; i < motion.axis.length; i += 2) {
            polygon.push(motion.axis[i] / captureWindow.width);
            polygon.push(motion.axis[i + 1] / captureWindow.height);
          }

          image.config('polygon', polygon);
        } else {
          image.config('rectangle', {
            x: motion.x / captureWindow.width,
            y: motion.y / captureWindow.height,
            width: motion.width / captureWindow.width,
            height: motion.height / captureWindow.height
          });
        }
      });
    },

    handleViewcellFisheye(options = {}, resolution) {
      const isLocalDewarp = options.stream_type !== 0
                            || options.mount_type === 'local_dewarp';

      if (!isLocalDewarp || options.stream_type === undefined) {
        this.isLocalDewarped = false;
        this.viewinfo.dewarpInfo.isLocalDewarped = false;

        // reset fisheye's resolution
        if (resolution) {
          this.setFisheyeResolution(resolution.width, resolution.height);
        }
        // reset fisheye's mount type
        if (options.mount_type) {
          this.setMountType(options.mount_type);
        }
      } else {
        this.isLocalDewarped = true;
        this.viewinfo.dewarpInfo.isLocalDewarped = true;
      }

      if (isLocalDewarp || !(this.image)) {
        return;
      }

      this.image.config('source', ({x: options.center_x, y: options.center_y, r: options.radius}));
    },

    handleViewcellError(err) {
      // eslint-disable-next-line no-console
      console.error(`${err.toString()} in channel: ${this.channel} and stream: ${this.stream}`);

      // pause by browser when switching tab
      if (err.toString().startsWith('AbortError: The play() request was interrupted by a call to pause().')) {
        this.retry();
        return;
      }
      switch (err.toString()) {
        case 'Error: Internal Server Error':
        case 'Error: Player Timeout':
        case 'Error: Bad Request':
        case 'Error: Destination unreachable':
        case 'Error: Continuous play failed':
          this.retry();
          break;
        case 'Error: Buffer length limitation':
          this.stop().then(() => this.retry());
          break;
        case 'Error: codec: JPEG is not support':
        default:
          this.stop();
          this.$emit('error', err);
          break;
      }
    },

    handleVisibilityChange() {
      if (document.hidden) {
        this.stopByVisibilityChange();
      } else if (this.isIdle) {
        this.play();
      }
    },

    stopByVisibilityChange() {
      this.stopByVisibilityChangeTimeout = setTimeout(() => {
        this.stopByDocumentHidden();
        this.clearStopByVisibilityChange();
      }, 10 * 1000);
    },

    clearStopByVisibilityChange() {
      if (this.stopByVisibilityChangeTimeout) {
        clearTimeout(this.stopByVisibilityChangeTimeout);
      }
      this.stopByVisibilityChangeTimeout = null;
    },

    stopByDocumentHidden() {
      if (!document.hidden) {
        return;
      }

      this.stop();
    },

    retry(wait = 5000) {
      this.retryConnect = setTimeout(() => this.replay(), wait);
      this.$emit('retry');
    },

    createLensImage(params) { // from liveview
      this.resizeJoystick();
      const lens = new ThreadableCanvas(params);
      return lens
        .config('pip', this.pipInfo)
        .config('vcaConfig', { displayHumanAs2D: false, showPosition: true, showCellMotion: true })
        .config('humanDetectionArea', this.showPeopleDetectionArea && this.viewinfo.peopleDetectionArea)
        .config('rule', this.rule)
        .config('showVCA', this.enableVCA)
        .config('joystick', this.joystick);
    },

    createLuteinImage(params) { // from liveview //fisheye
      params.ssmuxInstance = this.viewcell.ssmux;
      params.server = ThreadableCanvas.SERVER_TYPE.FISHEYE;
      this.image = new ThreadableCanvas(params)
        .config('vcaConfig', { displayHumanAs2D: false, showPosition: true, showCellMotion: true })
        .config('presentMode', PLUGINFREE.DEWARP[this.dewarpType] || 0);
      return this.image;
    },

    createResizeObserver() {
      this.resizeObs = new ResizeObserver(([entry]) => {
        if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
        }

        this.resizeTimeout = setTimeout(() => {
          this.resizeTimeout = null;
          this.resizeJoystick();
        }, 100);
      });
      this.resizeObs.observe(this.$refs.videoWrapper);
    },

    removeResizeObserver() {
      this.resizeObs.disconnect();
    },

    resizeJoystick() {
      const { joystick } = this;
      const { offsetWidth, offsetHeight } = this.$refs.videoWrapper;

      if (!joystick) {
        return;
      }

      joystick.resize({
        width: offsetWidth,
        height: offsetHeight,
      });
    },

    handleFisheyePreset(preset) {
      this.$emit('dewarpPreset', preset);
    },

    handleFisheyeIsValid(isValid) {
      this.fisheyeIsValid = isValid;
    },

    handleCanvasWrapperMousedown() {
      this.mousemoved = false;
    },

    handleCanvasWrapperMousemove() {
      this.mousemoved = true;
    },

    handleCanvasWrapperClick(evt) {
      if (this.isActiveView && this.mousemoved) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    },

    setFisheyeResolution(width, height) {
      if (!this.hasImage) {
        return;
      }

      this.image.config('resolution', {width, height});
    },

    createImage() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const { isFisheye } = this.viewcell;
          const player = this.viewcell.getPlayer();
          // H.265 needs lazyMode: false

          if (!player) {
            reject();
            return;
          }

          let params = {
            source: player,
            lazyMode: false,
          };

          if (isFisheye) {
            this.image = this.createLuteinImage(params);
          } else {
            const { offsetWidth, offsetHeight } = this.$refs.canvasWrapper;

            params = Object.assign(params, {
              stretch: this.stretch,
              width: offsetWidth,
              height: offsetHeight,
            });

            this.image = this.createLensImage(params);
          }
          if (this.rule) {
            this.image.config('rule', this.rule);
          }
          if (this.camera.projection) {
            this.image.config('projection', this.camera.projection);
          }

          if (this.isYUVCanvas) {
            this.removePlayerFromWrapper();
          }

          this.appendImageToWrapper(this.image.element);

          resolve(this.image);
        });
      });
    },

    removeImage() {
      if (!this.image) {
        return;
      }

      this.$refs.canvasWrapper.removeChild(this.image.element);
      this.image.destroy();
      this.image = null;
    },

    createRtspChannel() {
      return new Promise((resolve, reject) => {
        const rtspWebsocket = new WebSocket(this.wsSrc, 'vvtk-protocol');
        const rtspChannel = new RtspProtocol(rtspWebsocket);
        const promiseReject = () => reject(new Error('WebSocket disconnect'));

        rtspChannel.on('close', promiseReject);
        rtspWebsocket.onopen = () => {
          rtspChannel.off('close', promiseReject);
          rtspChannel.on('close', this.handleRtspChannelClose);
          resolve(rtspChannel);
        };

        this.rtspChannel = rtspChannel;
      });
    },

    getRtspChannel() {
      if (this.rtspChannel) {
        return Promise.resolve(this.rtspChannel);
      }

      return this.createRtspChannel();
    },

    closeRtspChannel() {
      if (!this.rtspChannel) {
        return;
      }
      this.rtspChannel.off('close', this.handleRtspChannelClose);
      this.rtspChannel.close();
      this.rtspChannel = null;
    },

    handleRtspChannelClose() {
      // eslint-disable-next-line no-console
      console.warn(`camera (${this.channel})'s stream ${this.stream} disconnect`);

      this.rtspChannel.off('close', this.handleRtspChannelClose);
      this.rtspChannel = null;
      if (!this.viewcell?.playing) {
        this.removeImage();
        this.$emit('end');
      }
    },

    handleMetjEvent(metj) {
      if (!this.image) {
        return;
      }
      this.image.config('metadata', metj);
    },

    handleTimestamp(timestamp) {
      // XXX: Maybe we should emit the event and let parent to save it for us,
      // but this is too frequent.

      this.viewinfo.cameraTime = {
        timestamp: this.cameraTime,
        tzoffs: this.cameraTzoffs
      };
    },
    handleCurrentTimeUpdate() {
      // XXX: This is breaking if we specify playTime in the same viewcell again;
      // it will start from 0 from the new playTime.
      this.viewinfo.elapsedTime = this.viewcell?.getPlayerCurrentTime();
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    this.tryToPlayLooped();
    this.createResizeObserver();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .pluginless {
    background-color: #000;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .fill {
    width: 100%;
    height: 100%;
  }
  .position {
    position: absolute;
    top: 0;
    left: 0;
  }

  canvas {
    .fill();
  }

  .video_wrapper {
    .fill();
    .position();
    display: flex;
    justify-content: center;
    align-items: center;
    &.stretch{
      ::v-deep video, ::v-deep canvas {
        object-fit: fill;
        width: 100% !important;
        height: 100% !important;
        max-height: initial;
      }
    }

    ::v-deep video {
      .fill();
      position: absolute;
    }

    ::v-deep canvas {
      max-width:100%;
      max-height:100%;
      position: absolute;
      width: 100%;
    }
  }

  .canvas_wrapper {
    .fill();
    .position();
    display: flex;
    justify-content: center;
    align-items: center;

    &.stretch{
      ::v-deep canvas {
        object-fit: fill;
        width: 100% !important;
        height: 100% !important;
        max-height: initial;
      }
    }

    ::v-deep canvas {
      .fill();
      object-fit: contain;
      position: absolute;
      height: 100%;
      outline: none;
    }
  }
</style>
