import binaryTools from '@vivotek/lib-utility/binaryTools';
import {
  checkStringInHead,
} from '../rtspTools';

import RtspRequest from './RtspRequest';
import RtspMessagePair from './RtspMessagePair';
import METHODS from '../constants/method';

/**
 * @class
 * @classdesc RTSP parser
 */
class RtspParser {
  /**
   * @constructor
   * @param {Object} ref - reference to object
   */
  constructor(ref) {
    /**
     * @type {Object}
     */
    this.ref = ref;
    /**
     * @type {string}
     */
    this.remained = '';
    /**
     * @type {number}
     */
    this.interleaveChannelIndex = 0;
    /**
     * @type {number}
     */
    this.CSeq = 0;
    /**
     * @type {string}
     */
    this.userAgent = 'RTPExPlayer';
    /**
     * @type {string}
     */
    this.rtspServer = '';
  }

  /**
   * Use RTSP
   * @returns {RtspParser} - reference to the instance of the class
   */
  useRTSP() {
    this.userAgent = 'WebRTSP';
    return this;
  }

  /**
   * Use RTPExPlayer
   * @returns {RtspParser} - reference to the instance of the class
   */
  useRTSPLike() {
    this.userAgent = 'RTPExPlayer';
    return this;
  }

  /**
   * Update the server
   * @param {string} server - server
   */
  updateServer(server) {
    if (server) {
      this.rtspServer = server;
    }
  }

  /**
   * Create a request object
   * @param {Object} config - configuration object
   * @param {number} timeout - timeout
   * @returns {RtspMessagePair} - message pair object
   */
  createRequest(config, timeout = 30) {
    this.CSeq += 1;
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.CSeq = this.CSeq;
    config.headers['User-Agent'] = `${this.userAgent}/1.0.0`;
    const method = config.method.toLowerCase();
    // NOTE: ONLY FOR DEMO
    if (this.authenticator) {
      config.headers.Authorization = `${this.authenticator(config.method, config.url)}`;
    }
    if (this.authenticator && config.headers.Range) {
      // NOTE: NVR does not accept "Range: clock="
      delete config.headers.Range;
    }
    if (method === METHODS.OPTIONS.toLowerCase()) {
      this.interleaveChannelIndex = 0;
    } else if (method === METHODS.SETUP.toLowerCase()) {
      config.headers.Date = `${new Date().toUTCString()}`;
      if (!config.headers.Transport) {
        config.headers.Transport = `RTP/AVP/TCP;unicast;interleaved=${this.interleaveChannelIndex}-${this.interleaveChannelIndex += 1}`;
      }
    }
    if (this.session) {
      config.headers.Session = this.session;
    }
    delete config.method;
    return new RtspMessagePair(
      new RtspRequest[method](config),
      timeout,
      this,
    );
  }

  /**
   * Parse message over connection and detect if it is a rtsp
   * packet or a rtsp message(response/request)
   * @param {Object} message - message object over websocket
   * @returns {Array} - parsed message array
   */
  parse(message) {
    const msgData = message.data;
    const arrayData = new Uint8Array(msgData);

    // check 'RTSP/1.0 ...'
    const isRtspResponse = checkStringInHead(arrayData, 'RTSP');
    // check '${COMMAND} rtsp://...'
    const isRtspRequest = checkStringInHead(arrayData, METHODS.ANNOUNCE)
      || checkStringInHead(arrayData, METHODS.OPTIONS)
      || checkStringInHead(arrayData, METHODS.DESCRIBE)
      || checkStringInHead(arrayData, METHODS.SETUP)
      || checkStringInHead(arrayData, METHODS.PLAY)
      || checkStringInHead(arrayData, METHODS.PAUSE)
      || checkStringInHead(arrayData, METHODS.TEARDOWN)
      || checkStringInHead(arrayData, METHODS.GET_PARAMETER)
      || checkStringInHead(arrayData, METHODS.SET_PARAMETER)
      || checkStringInHead(arrayData, METHODS.REDIRECT);

    if (!isRtspResponse && !isRtspRequest) {
      return this.ref && this.ref.handlePacket(arrayData, message);
    }
    return this.parseRtspMessage(binaryTools.ab2str(message.data));
  }

  /**
   * Use ref's sdpParser to parse a response including sdp.
   * Used by RtspMessagePair.response when DESCRIBE request is responsed
   * with sdp data.
   * @param {String} data - raw sdp data including new lines
   * @returns {Object} - a parsed object for sdp
   */
  parseSdp(data) {
    return this.ref.sdpParser.parse(data);
  }

  /**
   * Parse rtsp message
   * @param {String} data - rtsp message string
   * @returns {Array} - array of parsed rtsp message object
   */
  parseRtspMessage(data) {
    const lines = [...(this.previous ?? []), ...data.split('\n')];
    let headers = {};
    let mediaHeaders = [];
    let content = [];
    let statusCode;
    let statusText;

    let remainedData = null;
    const result = [];
    lines.forEach((_line, index) => {
      const line = _line.trim();
      if (line.length === 0) {
        remainedData = lines.slice[index];
        return; // end of sdp data
      }
      content.push(line);
      if (line.indexOf('RTSP/') >= 0) {
        if (statusCode) {
          result.push({
            status: Number(statusCode),
            statusText: statusText.join(' '),
            headers,
            mediaHeaders,
            content: content.join('\n')
          });
          headers = {};
          mediaHeaders = [];
          content = [];
        }
        [, statusCode, ...statusText] = line.split(' ');
        return;
      }

      if (line[1] === '=') {
        mediaHeaders.push(line.trim());
      } else if (line) {
        const split = line.split(':');
        const $data = split.slice(1).join(':').trim();

        headers[split[0].trim().toLowerCase()] = $data;
      }
    });

    result.push({
      id: +headers.cseq,
      status: Number(statusCode),
      statusText: statusText.join(' '),
      headers,
      mediaHeaders,
      content: content.join('\n')
    });
    this.previous = remainedData;
    if (this.ref) {
      this.ref.handleRtspMessages(result);
    }
    return result;
  }
}

RtspParser.METHODS = METHODS;

export default RtspParser;
