import { getTagged, Logger } from '@vivotek/lib-utility/logger';
import {
  checkIsRTPData, combinePacketArray, readUInt16BE,
} from '../rtspTools';

import NaluAssembler from '../v2/utils/NaluAssembler';

const Log = getTagged('rtsp-protocol:packet-manager', Logger.DEBUG);

export default class PacketManager {
  constructor(ref) {
    this.naluAssembler = new NaluAssembler();
    this.rtpFactory = ref.rtpFactory;
    this.payloadQueue = {};
    this.packets = [];
    this.packetLength = 0;
    this.timeOffset = {};
    this.rtpBuffer = {};
    this.lastTimestamp = {};
    this.ref = ref;
  }

  initializeTrackMap(track) {
    const { trackID } = track;
    this.rtpBuffer[trackID] = [];
    this.timeOffset[trackID] = 0;
    this.payloadQueue[trackID] = [];
  }

  destroy() {
    this.payloadQueue = {};
    this.packets = [];
    this.timeOffset = {};
    this.rtpBuffer = {};
    this.lastTimestamp = {};
  }

  parseRTPData(dataArray, preLength, parseCallback) {
    let pLength;
    let pPiece;
    let combinePackets;
    let prefill = 0;
    let bArray = dataArray;

    if (preLength > 0) {
      if (bArray.byteLength >= preLength) {
        this.packets.push(bArray.slice(0, preLength));
        combinePackets = combinePacketArray(this.packets);
        parseCallback(combinePackets.buffer ? combinePackets.buffer : combinePackets);

        this.packets = [];
        this.packetLength = 0;
        bArray = bArray.slice(preLength);
      } else {
        this.packetLength -= bArray.byteLength;
        this.packets.push(bArray);
        return [];
      }
    }

    if (!checkIsRTPData(bArray) || bArray.length < 4) {
      return bArray;
    }

    pLength = readUInt16BE(bArray, 2);

    if (pLength >= 65535 && bArray.length < 8) {
      return bArray;
    }

    if (pLength >= 65535) {
      pLength = readUInt16BE(bArray, 4, 4);
      pPiece = bArray.slice(8, pLength + 8);
      prefill = 4;
    } else {
      pPiece = bArray.slice(4, pLength + 4);
    }

    if (pLength - pPiece.length <= 0) {
      parseCallback(pPiece.buffer);
      return this.parseRTPData(bArray.slice(pLength + 4 + prefill), -1, parseCallback);
    }

    return bArray;
  }

  handlePacket(packet) {
    let arrayData = packet;
    let extraData;
    let packetPiece;

    if (!checkIsRTPData(packet) && this.packets.length && this.packetLength === 0) {
      const combinePackets = combinePacketArray([...this.packets, packet]);
      arrayData = new Uint8Array(combinePackets.buffer ? combinePackets.buffer : combinePackets);
      this.packets = [];
    }

    if (checkIsRTPData(arrayData) || this.packetLength > 0) {
      extraData = this.parseRTPData(arrayData, this.packetLength, this.processRTSPPacket);
      if (extraData.length <= 0) {
        return;
      }

      if (extraData.length < 4) {
        this.packetLength = 0;
        this.packets.push(extraData);
        return;
      }

      this.packetLength = readUInt16BE(extraData, 2);

      if (this.packetLength >= 65535 && extraData.length < 8) {
        this.packetLength = 0;
        this.packets.push(extraData);
        return;
      }

      if (this.packetLength >= 65535) {
        this.packetLength = readUInt16BE(extraData, 4, 4);
        packetPiece = extraData.slice(8, this.packetLength + 8);
      } else {
        packetPiece = extraData.slice(4, this.packetLength + 4);
      }

      this.packetLength -= packetPiece.length;
      this.packets.push(packetPiece);
    }
  }

  processRTSPPacket = (packet) => {
    const data = new Uint8Array(packet);
    const rtpData = this.rtpFactory.build(data);
    const { trackID } = rtpData;

    if (!rtpData.media) {
      // continuous receive unknown payload type 72
      // which is not recorded in sdp
      // TODO: need to check with camera RD
      // Log.debug('Receive unsupport media packet', rtpData);
      return;
    }

    if (rtpData.media && rtpData.getPayload().byteLength === 0) {
      Log.debug('Received media packet with empty data.\nPacket details:', rtpData);
      return;
    }

    if (rtpData.isVideo && !this.firstVideoPacket) {
      this.firstVideoPacket = rtpData;
      Log.debug('Received the first video packet.\nPacket details:', rtpData);
    }

    if (this.timeOffset[trackID] === undefined) {
      this.rtpBuffer[trackID].push(rtpData);
      return;
    }

    if (this.lastTimestamp[trackID] === undefined) {
      this.lastTimestamp[trackID] = rtpData.timestamp - this.timeOffset[trackID];
    }

    const queue = this.rtpBuffer[trackID];
    queue.push(rtpData);

    while (queue.length) {
      try {
        const rtp = queue.shift();
        rtp.timestamp -= (this.timeOffset[rtp.trackID] + this.lastTimestamp[rtp.trackID]);
        if (rtp.media) {
          let payload = null;

          if (rtp.isVideo) {
            payload = this.naluAssembler.onNALUFragment(rtp);
          } else if (rtp.isAudio) {
            payload = {
              data: rtp.getPayload(),
              applicationData: rtp.applicationData
            };
          }

          if (payload) {
            this.payloadQueue[rtp.trackID].push(payload);
          }

          this.ref.emit(rtp.media.trackID, rtp.trackID);
        }
      } catch (error) {
        this.ref.emit('error', error);
      }
    }
  }
}
