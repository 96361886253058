<template>
  <div
    class="account-button-container"
    v-on-clickaway="onAccountDropdownClickaway"
    data-testid="header-account-button"
  >
    <SvgIcon
      iconClass="general_account"
      @click="toggleAccountButton"
      size="28px"
      outerSize="48px"
      :active="isAccountDropdownVisible || isLanguageDropdownVisible"
    />
    <!-- account dropdown -->
    <div
      class="account-dropdown"
      v-if="isAccountDropdownVisible"
    >
      <div class="options-container">
        <h5 class="name">
          {{ user.userName }}
        </h5>
        <p
          class="privilege"
          :data-privilege-group="privilege"
        >
          {{ privilegeText }}
        </p>
      </div>
      <div class="option-separator" />
      <div class="options-container">
        <div
          class="option"
          @click="showLanguageDropdown"
          data-testid="option-language"
        >
          <p class="language">
            {{ $t('Language') }}
          </p>
          <SvgIcon
            iconClass="general_arrow_right"
            size="20px"
          />
        </div>
      </div>
      <template v-if="isAdministrator">
        <div class="option-separator" />
        <div class="options-container">
          <div
            class="option"
            @click="doRestart"
            data-testid="option-restart"
          >
            <p class="language">
              {{ $t('Restart') }}
            </p>
          </div>
        </div>
      </template>
      <div class="option-separator" />
      <div class="options-container">
        <div
          class="option logout"
          @click="doLogout"
          data-testid="option-logout"
        >
          <p>
            {{ $t('Log out') }}
          </p>
        </div>
      </div>
    </div>
    <!-- language dropdown -->
    <div
      class="language-dropdown"
      v-if="isLanguageDropdownVisible"
    >
      <div class="options-container">
        <div
          class="option"
          @click="hideLanguageDropdown"
        >
          <SvgIcon
            class="icon"
            iconClass="general_arrow_left"
            size="20px"
          />
          <p class="back">
            {{ $t('Choose your language') }}
          </p>
        </div>
        <div
          :class="{
            option: true,
          }"
          v-for="({text, value}) in languages"
          :key="value"
          @click="switchLanguage(value)"
        >
          <SvgIcon
            class="icon"
            :iconClass="$i18n.locale() === value ? 'general_check' : 'placeholder'"
            size="20px"
          />
          <p>
            {{ text }}
          </p>
        </div>
      </div>
    </div>
    <AppDialogue
      class="restartDialogue"
      name="restartDialogue"
      ref="restartDialogue"
      :title="$t('Are you sure you want to restart?')"
      :primaryText="$t('Restart')"
      :secondaryText="$t('Cancel')"
      :closeWhenClickButton="true"
      @confirm="onClickRestartDialogConfirm"
    >
      <div
        slot="content"
        class="default-dialog-content"
      >
        <h6>
          {{ $t('There will be no recording while restarting the system.') }}
        </h6>
        <h6>
          {{ $t('Are you sure you want to restart?') }}
        </h6>
      </div>
    </AppDialogue>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';
import i18n from '@/i18n/index';
import AppDialogue from '@/components/ui/AppDialogue.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppHeaderAccountButton',
  data() {
    return {
      isAccountDropdownVisible: false,
      isLanguageDropdownVisible: false,
    };
  },
  directives: {
    onClickaway,
  },
  components: {
    AppDialogue,
  },
  methods: {
    ...mapActions('ui', ['navigate']),
    ...mapActions('user', ['logout', 'restart']),
    toggleAccountButton() {
      this.isAccountDropdownVisible = !this.isAccountDropdownVisible;
    },
    onAccountDropdownClickaway() {
      this.isAccountDropdownVisible = false;
      this.isLanguageDropdownVisible = false;
    },
    showLanguageDropdown() {
      this.isLanguageDropdownVisible = true;
    },
    hideLanguageDropdown() {
      this.isLanguageDropdownVisible = false;
    },
    switchLanguage(lang) {
      i18n.switchLanguage(lang);
    },
    doLogout() {
      this.navigate({
        url: `${window.location.origin}/index.html`,
        callback: this.logout,
      });
    },
    doRestart() {
      this.onAccountDropdownClickaway();
      this.$refs.restartDialogue.show();
    },
    onClickRestartDialogConfirm(confirm) {
      if (confirm) {
        this.restart();
      }
    },
  },
  computed: {
    ...mapState(['user', 'ui']),
    ...mapGetters('user', ['isAdministrator']),
    ...mapGetters('search', ['currentResult']),
    languages() {
      return Object.keys(i18n.languages).map((language) => ({ text: i18n.languages[language], value: language }));
    },
    privilege() {
      return this.user.group === 'administrator' ? 'admin' : 'regular-user';
    },
    privilegeText() {
      return this.privilege === 'admin' ? this.$t('administrator') : this.$t('regular user');
    }
  },
};
</script>
<style lang="less" scoped>
.dropdown {
  position: absolute;
  top: rem(0);
  right: rem(0);
  min-width: rem(280);
  background: #2e2e2e;
  border: 1px solid #000000;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}
.account-dropdown {
  .dropdown();
  z-index: 999;
  transform: translateY(rem(48)) translateX(-40%);
  .option {
    p.language {
      cursor: pointer;
      line-height: rem(20);
      width: rem(214);
      margin-right: rem(10);
    }
    &.logout,
    &.old-version {
      cursor: pointer;
    }
  }
}
.language-dropdown {
  .dropdown();
  z-index: 1000;
  transform: translateY(rem(48)) translateX(-40%);

  .option {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    > p {
      margin-left: rem(12);
    }
    > .back {
      line-height: rem(20);
    }
  }
}
.options-container {
  padding: rem(10) 0;
  margin-left: rem(24);
  > .name {
    margin-top: rem(8);
  }
  > .privilege {
    display: inline-block;
    width: auto;
    font-size: rem(12);
    margin-top: rem(8);
    margin-bottom: rem(8);
    height: rem(16);
    line-height: rem(16);
    padding: rem(2) rem(6);
    text-transform: uppercase;
    font-size: 12px;
    color: #2e2e2e;
    text-align: center;

    &[data-privilege-group="admin"] {
      background-color: #66ffff;
    }
    &[data-privilege-group="regular-user"] {
      background-color: #dddddd;
    }
  }
}

.option {
  height: rem(54);
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
}

::v-deep .restartDialogue {
  .header {
    h5 {
      color: #ffb029;
    }
  }
  .dialogue-button.primary {
    background: #ffb029;
    border: 1px solid #ffb029;
    color: #000000;
  }
}
</style>
