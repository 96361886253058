export default class VAOMCore {
  constructor(ref) {
    this.ref = ref;
  }

  clear() {
    if (this.ref.shapes.length) {
      this.ref.shapes.forEach((shape) => shape.destroy && shape.destroy());
      this.ref.shapes = [];
      this.ref.reference.requestPaint();
    }
  }

  draw(condition, ...args) {
    // if condition is simple boolean, use it.
    // otherwise the condition might be an object, which means it is complex to decide it here,
    // so we pass it to shape.draw at the rear.
    if (typeof condition === 'boolean') {
      if (condition) {
        this.ref.shapes.forEach((shape) => shape.draw(...args));
      }
    } else {
      this.ref.shapes.forEach((shape) => shape.draw(...args, condition));
    }
  }

  addShape(shape) {
    this.ref.shapes.push(shape);
  }

  updateShapes(shapes) {
    this.ref.shapes = shapes || [];
  }
};