import {
  isIE,
  checkIsRTPData,
  readUInt16BE,
  appendBuffer,
  parseBinaryToJSON,
} from '../rtspTools';

export default class PacketManager {
  constructor(ref) {
    this.packets = [];
    this.packetLength = 0;
    this.trackMap = {};
    this.ref = ref;
  }

  initializeTrackMap(content) {
    const tracks = this.ref.sdpParser.parse(content);
    tracks.forEach((track) => {
      track.mediatype = track.type;
      track.mimetype = track.type; // XXX: refine
      switch (track.type) {
        case 'application':
          this.trackMap[track.trackID] = function processTrack(id, notify) {
            const parsedNotify = parseBinaryToJSON(notify);
            // user data event
            this.ref.trigger(id, parsedNotify);
          }.bind(this, track.trackID);
          break;

        default:
          this.trackMap[track.trackID] = function processTrack(id, packet, param) {
            // streaming packet event
            this.ref.trigger(id, packet, param);
          }.bind(this, track.trackID);
          break;
      }
    });
    return tracks;
  }

  destroy() {
    this.packets = [];
    this.packetLength = 0;
    this.trackMap = {};
  }

  handlePacket(arrayData, message) {
    let extraData,
      packetPiece;

    const parseRTPData = (dataArray, preLength, parseCallback) => {
      let pLength;
      let pPiece;
      let combinePackets = null;
      let prefill = 0;
      let bArray = dataArray;

      if (preLength > 0) {
        if (bArray.byteLength >= preLength) {
          if (isIE) {
            this.packets.push(message);
            combinePackets = this.packets.reduce((prev, curr) => {
              if (!prev) {
                return curr;
              }

              prev.AppendData(curr.data);
              return prev;
            }, combinePackets);

            parseCallback(combinePackets, this.headForIE);
          } else {
            this.packets.push(bArray.slice(0, preLength));
            combinePackets = this.packets.reduce((prev, curr) => {
              if (!prev) {
                return curr;
              }

              return appendBuffer(prev, curr);
            }, combinePackets);
            // parseCallback(appendBuffer(packets[0], packets[1]).buffer);
            parseCallback(combinePackets.buffer ? combinePackets.buffer : combinePackets);
          }

          this.packets = [];
          this.packetLength = 0;
          bArray = bArray.slice(preLength);
        } else {
          this.packetLength -= bArray.byteLength;
          if (isIE) {
            this.packets.push(message);
          } else {
            this.packets.push(bArray);
          }
          // packets.push(bArray);
          return [];
        }
      }

      if (!checkIsRTPData(bArray)) {
        return bArray;
      }

      pLength = readUInt16BE(bArray, 2);

      if (pLength >= 65535) {
        pLength = readUInt16BE(bArray, 4, 4);
        pPiece = bArray.slice(8, pLength + 8);
        prefill = 4;

        this.headForIE = 8;
      } else {
        pPiece = bArray.slice(4, pLength + 4);
        this.headForIE = 4;
      }

      if (pLength - pPiece.length <= 0) {
        if (isIE) {
          parseCallback(message, this.headForIE);
        } else {
          parseCallback(pPiece.buffer);
        }
        // parseCallback(pPiece.buffer);

        return parseRTPData(bArray.slice(pLength + 4 + prefill), -1, parseCallback);
      }

      return bArray;
    };

    // set data callback
    if (checkIsRTPData(arrayData)) {
      this.emitRTPData = this.trackMap[arrayData[1]] || function emitRTPData() {};
    }

    if (checkIsRTPData(arrayData) || this.packetLength > 0) {
      extraData = parseRTPData(arrayData, this.packetLength, this.emitRTPData);
      if (extraData.length <= 0) {
        return;
      }

      this.packetLength = readUInt16BE(extraData, 2);

      if (this.packetLength >= 65535) {
        this.packetLength = readUInt16BE(extraData, 4, 4);
        packetPiece = extraData.slice(8, this.packetLength + 8);
      } else {
        packetPiece = extraData.slice(4, this.packetLength + 4);
      }

      this.packetLength -= packetPiece.length;
      if (isIE) {
        this.packets.push(message);
      } else {
        this.packets.push(packetPiece);
      }
    }
  }
}
