export default {
  ab2str: (buf) => [].map.call(new Uint8Array(buf), (byte) => String.fromCharCode(byte)).join(''),
  str2ab(str) {
    const buf = new ArrayBuffer(str.length); // 2 bytes for each char
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i += 1) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  },
  str2dataStr: (encodedString) => decodeURIComponent(escape(encodedString)),
  dataStr2str: (unicodecharacters) => unescape(encodeURIComponent(unicodecharacters)),
};
