import XMLParser from './MetadataParserVerXML.mjs';
import JSONParser from './MetadataParserVerJSON.mjs';
export default class MetadataManager {
  constructor(reference) {
    this.reference = reference;
    this.shapes = [];
    this.parsers = [new XMLParser(reference, this), new JSONParser(reference, this)];
  }
  
  handle(data) {
    if (!data) {
      return;
    }
    this.parsers.forEach((parser) => {
      parser.parse(data);
    });
    this.reference.requestPaint();
  }

  drawIfNecessary() {
    if (this.reference.drawMetadataName || this.reference.displayConfig.showPosition || this.reference.displayConfig.showTrackingBlock || this.reference.displayConfig.showObjectHeight) {
      this.shapes.forEach((shape) => shape.draw(ctx, canvas.width, canvas.height, this.reference.drawMetadataName, this.reference.displayConfig.showPosition, this.reference.displayConfig.showTrackingBlock, this.reference.displayConfig.showObjectHeight));
    }
  }
  
  clear() {
    if (this.shapes.length) {
      this.shapes.splice(0, this.shapes.length);
      this.reference.requestPaint();
    }
  }

  update(rectangles) {
    if (JSON.stringify(this.shapes) !== JSON.stringify(rectangles)) {
      this.shapes = rectangles;
      this.reference.requestPaint();
    }
  }
}
