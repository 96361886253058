import { getTagged } from '@vivotek/lib-utility/logger';
import Cassiae from './cassiae';
import generateWebglContext from './generateWebglContext';
import VcaCanvas from './VcaCanvas';
import $postMessage from './postMessage';
import PLUGINFREE from './constants/pluginfree';

const Log = getTagged('threading-canvas:fisheye-image-server');

/**
 * FisheyeImageServer takes cassiae which is the wrapper of ssmux
 * as its one of the canvas handlers which deal with webgl/dewarp
 *
 * We don't need crop/stretch canvas handler.
 */
export default class FisheyeImageServer {
  constructor({ destination }, ssmuxInstance, client) {
    const ENVIRONMENT_IS_WORKER = typeof importScripts === 'function';
    this.source = null;
    this.destination = destination;
    this.finalContext = generateWebglContext(this.destination);
    this.sourceWidth = 0;
    this.sourceHeight = 0;
    this.targetWidth = 0;
    this.targetHeight = 0;
    this.ssmuxInstance = ssmuxInstance;
    this.client = client;
    this.presentMode = 0;
    this.mountType = 0;
    this.dewarpable = false;
    this.roi = [0, 0, this.targetWidth, this.targetHeight];
    this.vcaCanvas = new VcaCanvas(
      {
        useOffscreenCanvas: ENVIRONMENT_IS_WORKER,
      }
    );
    this.readyPromise = new Promise((resolve) => {
      // eslint-disable-next-line no-new
      new Cassiae({}, (cassiae) => {
        this.webglCanvas = cassiae;
        this.webglCanvas.setGL(this.finalContext, this.destination);
        this.webglCanvas.setPresentMode(this.presentMode); // 0 : 1O, 1: 1R, 2: 1P
        this.webglCanvas.enable1OVerification(true);
        if (this.targetWidth) {
          this.webglCanvas.setROI(...this.roi);
        }
        Log.debug('ssmux is ready.');
        resolve(this.webglCanvas);
      }, ssmuxInstance);
    });
  }

  ready() {
    return this.readyPromise.then(() => {
      Log.debug('Image server is ready.');
    });
  }

  send(data) {
    $postMessage({
      dispatch: 'message',
      event: {
        type: 'message',
        data,
      },
    }, this.client);
  }

  postMessage(data) {
    $postMessage(data, this);
  }

  terminate() {
    this.vcaCanvas.destroy();
    delete this.vcaCanvas;
    delete this.finalContext;
    delete this.source;
    delete this.destination;
  }

  command(command, ...args) {
    this[command](...args);
  }

  resize(width, height) {
    Log.debug('Resize', width, height);
    this.realWidth = width;
    this.realHeight = height;
    if (this.presentMode) {
      Log.debug('Has present mode');
      this.targetWidth = width;
      this.targetHeight = height;
    } else {
      Log.debug('Has no present mode');
      this.targetWidth = this.sourceWidth;
      this.targetHeight = this.sourceHeight;
    }
    Log.debug('Target size has been adjusted.', this.targetWidth, this.targetHeight);

    this.destination.width = this.targetWidth;
    this.destination.height = this.targetHeight;
    this.finalContext.viewportWidth = this.targetWidth;
    this.finalContext.viewportHeight = this.targetHeight;
    this.$configROI(width, height);
  }

  $configROI(width, height) {
    if (this.webglCanvas) {
      if (this.presentMode) {
        this.webglCanvas.setROI(0, 0, width, height);
      } else {
        this.webglCanvas.setROI(0, 0, this.sourceWidth, this.sourceHeight);
      }
    }
  }

  watch_vcaConfig(config) {
    this.vcaCanvas.displayConfig = {
      ...this.vcaCanvas.displayConfig,
      ...config,
    };
  }

  watch_showVCA(val) {
    this.vcaCanvas.enable = val;
  }

  render(imageBitmap, width, height) {
    this.sourceWidth = width;
    this.sourceHeight = height;
    this.resize(this.realWidth, this.realHeight);
    this.vcaCanvas.render(imageBitmap, width, height);
    this.webglCanvas && this.webglCanvas.renderVideo(this.vcaCanvas.destination);

    const isValid = this.webglCanvas.isValid();

    if (isValid !== this.dewarpable) {
      this.dewarpable = isValid;
      this.send({
        type: 'valid',
        data: isValid,
      });
    }
  }

  watch_presentMode(value) {
    Log.debug('server: present mode', value);
    if (Number.isNaN(value)) {
      // eslint-disable-next-line no-param-reassign
      value = PLUGINFREE.DEWARP[value];
    }
    this.webglCanvas && this.webglCanvas.setPresentMode(value);
    this.send({
      type: 'preset',
      data: this.webglCanvas.getPresetInfo(),
    });
  }

  /**
   * dewarp
   * @param {String} value - possible value is 1O, 1P, 1R, ...
   */
  watch_dewarpType(value) {
    Log.debug('server: dewarp type', value);
    this.webglCanvas && this.webglCanvas.setPresentMode(PLUGINFREE.DEWARP[value] || 0);
    this.send({
      type: 'preset',
      data: this.webglCanvas.getPresetInfo(),
    });
  }

  watch_source(source) {
    Log.debug('server: source', source);
    this.webglCanvas && this.webglCanvas.setCenterRadius(source.x, source.y, source.r);
  }

  watch_mountType(value) {
    this.mounttype = value;
    this.webglCanvas && this.webglCanvas.setMountType(value);
  }

  watch_roi(value) {
    this.roi = value;
    this.webglCanvas && this.webglCanvas.setROI(...value);
  }

  watch_presetInfo(event) {
    this.webglCanvas && this.webglCanvas.setPresetInfo(event);
  }

  handleEvent(evt) {
    if (this[`_handle_${evt.type}`]) {
      this[`_handle_${evt.type}`](evt);
    }
  }

  _handle_mousedown(event) {
    this.webglCanvas && this.webglCanvas.setMouseDown(event);
  }

  _handle_mousemove(event) {
    this.webglCanvas && this.webglCanvas.setMouseMove(event);
    this.webglCanvas && this.send({
      type: 'preset',
      data: this.webglCanvas.getPresetInfo(),
    });
  }

  _handle_mouseup(event) {
    this.webglCanvas && this.webglCanvas.setMouseUp(event);
  }

  _handle_wheel(event) {
    const delta = event.deltaY < 0 ? 0.1 : -0.1;
    this.webglCanvas && this.webglCanvas.setPTZ(0, 0, 0, delta);
    this.webglCanvas && this.send({
      type: 'preset',
      data: this.webglCanvas.getPresetInfo(),
    });
  }

  config(key, value) {
    this[key] = value;
    if (this[`watch_${key}`]) {
      this[`watch_${key}`](value);
    }
  }

  watch_projection(projection) {
    this.vcaCanvas.vcaProject = projection;
  }

  watch_rule(rule) {
    this.vcaCanvas.rules = rule;
  }

  watch_metadata(metadata) {
    this.vcaCanvas.metadata = metadata;
  }

  watch_rectangle(data) {
    this.vcaCanvas.addRectangle(data);
  }
}
