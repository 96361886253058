import { render, staticRenderFns } from "./AppSlider.vue?vue&type=template&id=2c440ea1&scoped=true&"
import script from "./AppSlider.vue?vue&type=script&lang=js&"
export * from "./AppSlider.vue?vue&type=script&lang=js&"
import style0 from "./AppSlider.vue?vue&type=style&index=0&id=2c440ea1&prod&lang=less&scoped=true&"
import style1 from "./AppSlider.vue?vue&type=style&index=1&id=2c440ea1&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-78585250f4/0/cache/vue-loader-npm-15.10.0-778eb54c35-1bf6c9ffea.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c440ea1",
  null
  
)

export default component.exports