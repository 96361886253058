<template>
  <div class="block-wrap">
    <div class="block-title">
      <h6 class="bold">
        {{ $t('Camera') }}
      </h6>
    </div>
    <div class="block-content">
      <AppCardMessage
        v-show="showSmartSearchHint"
        iconClass="general_hint"
        class="smart-search-hint"
      >
        <span
          slot="message"
          class="message"
        >
          <!-- eslint-disable-next-line max-len -->
          {{ $t('Cameras supporting Deep Search don\'t support Smart Search II. For precise results, please use Deep Search for these cameras.') }}</span>
      </AppCardMessage>
      <AppDropdown
        :options="cameraOptions"
        :multiple="isMultiple"
        :selectAllLabel="$t('All cameras')"
        :disabled="disabled || noEncoder"
        :isError="vModel.length === 0"
        :placeholder="placeholder"
        :showLabels="noEncoder"
        v-model="vModel"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppCardMessage from '@/components/ui/AppCardMessage.vue';
import AppDropdown from '@/components/ui/AppDropdown.vue';

export default {
  name: 'EncoderSelector',
  components: {
    AppCardMessage,
    AppDropdown
  },
  props: {
    value: {
      type: [Array, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true,
      default: 'bookmark'
    }
  },
  data() {
    return {
      isUpdated: false,
      showSmartSearchHint: this.type === 'smartSearch',
      isMultiple: this.type !== 'smartSearch'
    };
  },
  computed: {
    ...mapGetters('encoder', ['availableEncoders']),
    vModel: {
      get() {
        if (!this.isUpdated && this.isMultiple) {
          const result = this.cameraOptions.map((item) => item.value);
          this.$emit('input', result);
          return result;
        }
        return this.value;
      },
      set(value) {
        if (!this.isUpdated) {
          this.isUpdated = true;
        }
        this.$emit('input', value);
      }
    },
    cameraOptions() {
      return this.availableEncoders.reduce((init, item) => {
        if ((this.type === 'vca' && item.isVCA)
            || (this.type === 'smartSearch' && item.options.motion_cell)
            || (this.type === 'bookmark')) {
          init.push({
            text: item.name,
            value: Number(item.index)
          });
        }
        return init;
      }, []);
    },
    noEncoder() {
      return this.cameraOptions.length === 0;
    },
    placeholder() {
      if (this.noEncoder) {
        return this.$t('No cameras');
      }
      if (!this.isMultiple && this.vModel === -1) {
        return this.$t('Select 1 camera');
      }
      return '';
    }
  },
};
</script>

<style lang="less" scoped>
.block-wrap {
  .message {
    color: white;
  }
  .smart-search-hint {
    margin: 0 0 rem(5) 0;
  }
}
</style>
