import MicroEvent from '@vivotek/lib-utility/microevent';
import { getTagged, Logger } from '@vivotek/lib-utility/logger';
import RtspParser from '../models/RtspParser';
import SdpParser from '../models/SdpParser';
import PacketManager from './PacketManager';
import { appendBuffer } from '../rtspTools';

const Log = getTagged('rtsp-protocol', Logger.DEBUG);

function RtspProtocol(channel) {
  this.messageMap = {};

  this.channel = MicroEvent.mixin(channel);

  this.sdpParser = new SdpParser();
  this.rtspParser = new RtspParser(this);
  this.packetManager = new PacketManager(this);
  this.tracks = [];

  channel.binaryType = 'arraybuffer';

  if (channel.addEventListener) {
    channel.addEventListener('message', this);
    channel.addEventListener('close', this);
  } else if (channel.onmessage && channel.onclose) {
    channel.onmessage = (message) => {
      this._handle_message(message);
      this.trigger('message', message);
    };
    channel.onclose = (ev) => {
      this._handle_close(ev);
      this.trigger('close', ev);
    };
  }
}

RtspProtocol.prototype.handleEvent = function handleEvent(evt) {
  if (this[`_handle_${evt.type}`]) {
    this[`_handle_${evt.type}`](evt);
  }
};

RtspProtocol.prototype._handle_message = function processMessageEvent(message) {
  return this.rtspParser.parse(message);
};

RtspProtocol.prototype._handle_close = function processCloseEvent() {
  this.removeMessageMap();
  Log.error('WebSocket closed.');
  this.trigger('close');
};

RtspProtocol.prototype.handlePacket = function handlePacket(arrayData, message) {
  this.packetManager.handlePacket(arrayData, message);
};

RtspProtocol.prototype.handleRtspMessages = function handleRtspMessages(messages) {
  this.packets = [];
  this.packetLength = 0;
  messages.forEach((message) => {
    this.receiveRtspResponse(message);
  });
};

RtspProtocol.prototype.receiveRtspResponse = function receiveSdpResponse(response) {
  if (!this.messageMap[response.id]) {
    return;
  }

  this.messageMap[response.id].response(response);
  delete this.messageMap[response.id];
};

RtspProtocol.prototype.sendRtspCommand = function sendRtspCommand(message) {
  if (this.channel.readyState !== 'open' && this.channel.readyState !== 1) { // 'open' for datachannel, 1 for websocket
    return Promise.reject(new Error('Data channel is not open'));
  }
  const request = this.rtspParser.createRequest(message);
  this.messageMap[request.id] = request;
  return request.send(this.channel);
};

RtspProtocol.prototype.sendOptions = function sendOptions(url) {
  return this.sendRtspCommand({
    method: RtspParser.METHODS.OPTIONS,
    url,
    headers: {
      Require: 'implicit-play',
      'User-Agent': 'WebRTSP/0.0.0.1',
      'Proxy-Require': 'gzipped-messages',
    },
  }).then((response) => {
    this.rtspMethods = response.methods;
  });
};

RtspProtocol.prototype.sendDescribe = function sendDescribe(url, auth, hasBackchannel) {
  let $auth = '';
  if (typeof auth === 'string') {
    $auth = `username="${auth}"`;
  } else if (typeof auth === 'object') {
    $auth = `${Object.keys(auth).map((key) => `${key}="${auth[key]}"`).join(', ')}`;
  }
  return this.sendRtspCommand({
    method: RtspParser.METHODS.DESCRIBE,
    url,
    headers: {
      ...(hasBackchannel ? { Require: 'www.onvif.org/ver20/backchannel' } : {}),
      ...($auth ? { Authorization: $auth } : {}),
    },
  })
    .then((response) => {
      this.tracks = this.packetManager.initializeTrackMap(response.mediaHeaders.join('\r\n'));
      if (!response.mediaHeaders.length) {
        return Promise.reject(new Error('no describe tracks'));
      }
      return Promise.resolve([response.status, this.tracks]);
    });
};

RtspProtocol.prototype.sendBack = function sendBack(trackID, packet) {
  let filling = [36, trackID];
  let bLength = packet.byteLength.toString(16);

  // 'open' for datachannel, 1 for websocket
  if (this.channel.readyState !== 'open' && this.channel.readyState !== 1) {
    return;
  }

  if (packet.byteLength >= 65535) {
    filling.push(255, 255);
    bLength = (`0000000${bLength}`).substr(-8);
  } else {
    bLength = (`000${bLength}`).substr(-4);
  }

  for (let i = 0; i < bLength.length; i += 2) {
    filling.push(parseInt(bLength.substr(i, 2), 16));
  }

  filling = new Uint8Array(filling).buffer;
  filling = appendBuffer(filling, packet);

  this.channel.send(filling);
};

RtspProtocol.prototype.sendSetup = function sendSetup(url) {
  return this.sendRtspCommand({
    method: RtspParser.METHODS.SETUP,
    url,
    headers: {
      Transport: 'TCP/WS;unicast;interleave=1'
    },
  }).then((response) => Promise.resolve([response.status, response.headers.Session]));
};

RtspProtocol.prototype.sendPlay = function sendPlay(url, session = '', scale = 1, options = {}) {
  return this.sendRtspCommand({
    method: RtspParser.METHODS.PLAY,
    url,
    headers: {
      Range: 'now',
      Scale: scale,
      Session: session,
      ...options,
    }
  });
};

RtspProtocol.prototype.sendChange = function sendChange(url, session = '', scale = 1) {
  return this.sendRtspCommand({
    method: RtspParser.METHODS.CHANGE,
    url,
    headers: {
      Session: session,
      Scale: scale,
      Range: 'now',
    },
  });
};

RtspProtocol.prototype.sendPause = function sendPause(url, session = '') {
  return this.sendRtspCommand({
    method: RtspParser.METHODS.PAUSE,
    url,
    headers: {
      Session: session,
    },
  });
};

RtspProtocol.prototype.sendTeardown = function sendTeardown(url, session = '') {
  return this.sendRtspCommand({
    method: RtspParser.METHODS.TEARDOWN,
    url,
    headers: {
      Session: session,
    },
  }).then(() => {
    if (!this.tracks) {
      return;
    }
    this.tracks.forEach((track) => {
      this.removeTrack(track);
    });
    this.tracks = [];
  });
};

RtspProtocol.prototype.removeTrack = function removeTrack(track) {
  this.removeAllListeners(track.trackId);
  if (this.packetManager.trackMap[track]) {
    delete this.packetManager.trackMap[track];
  }
};

RtspProtocol.prototype.close = function close() {
  this.channel.close();
};

RtspProtocol.prototype.destroy = function destroy() {
  if (this.channel) {
    if (this.channel.removeEventListener) {
      this.channel.removeEventListener('message', this);
      this.channel.removeEventListener('close', this);
    } else if (this.channel.onmessage && this.channel.onclose) {
      this.channel.onmessage = null;
      this.channel.onclose = null;
    }
    this.channel = null;
  }

  this.removeMessageMap();
  this.packetManager.destroy();

  this.sdpParser = null;
  this.rtpFactory = null;
};

RtspProtocol.prototype.removeMessageMap = function removeMessageMap() {
  if (!this.messageMap) {
    return;
  }
  Object.keys(this.messageMap).forEach((id) => {
    this.messageMap[id].destroy();
    delete this.messageMap[id];
  });
};

export default MicroEvent.mixin(RtspProtocol);
