/**
 * @module RtspMessagePair
 */
import { getTagged, Logger } from '@vivotek/lib-utility/logger';
import METHODS from '../constants/method';

const Log = getTagged('rtsp-protocol', Logger.DEBUG);

/**
 * Represents a pair of RTSP messages: request and response.
 */
export default class RtspMessagePair {
  /**
   * Create a new instance of RtspMessagePair.
   *
   * @param {Request} request - The request message of the pair.
   * @param {number} [timeout=10] - The timeout of the request in seconds.
   * @param {SdpParser} parser - The Rtsp Parser.
   */
  constructor(request, timeout = 10, parser) {
    /**
     * The request message of the pair.
     *
     * @type {Request}
     */
    this.request = request;

    /**
     * The time when the request was sent in milliseconds since epoch.
     *
     * @type {number}
     */
    this.requestTime = new Date().getTime();

    /**
     * The ID of the request.
     *
     * @type {number}
     */
    this.id = request.id;

    /**
     * The timeout of the request in milliseconds.
     *
     * @type {Timeout}
     */
    this.timeout = setTimeout(() => {
      Log.error(`Request with CSeq ${this.request.id} timed out.\nRequest Details: ${this.request.toString()}`);
      this.request.reject(new Error('Request Timeout'));
    }, timeout * 1000);

    /**
     * The parser for managing rtsp messages.
     *
     * @type {RtspParser}
     */
    this.parser = parser;
  }

  /**
   * Send the request message of the pair.
   *
   * @param {Channel} channel - The channel to send the message on.
   * @returns {Promise<Response>} - The promise of the response message.
   */
  send(channel) {
    return this.request.send(channel);
  }

  /**
   * Destroy the pair by cancelling the request timeout.
   */
  destroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = null;
  }

  /**
   * Handle the response message of the pair.
   *
   * @param {Response} data - The response message.
   */
  response(data) {
    const responseTime = new Date().getTime() - this.requestTime;
    Log.log(`S->C:\n\n${data.content}\n\n> Responsed in ${responseTime} ms`);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (data.status === 200) {
      if (!this.request.resolve) {
        return;
      }
      const method = this.request.method.toLowerCase();
      if (method === METHODS.DESCRIBE.toLowerCase()) {
        data.tracks = this.parser.parseSdp(data.content);
      } else if (method === METHODS.OPTIONS.toLowerCase()) {
        if (data.headers.public) {
          data.methods = data.headers.public.split(',').map((e) => e.trim());
        }
        this.parser.updateServer(data.headers.server);
      } else if (method === METHODS.SETUP.toLowerCase()) {
        // XXX: refine
        this.parser.session = data.headers.session;
      }
      this.request.resolve(data);
    } else {
      if (!this.request.reject) {
        return;
      }
      this.request.reject(new Error(`${data.status} ${data.statusText}`));
    }
  }
}
