/* eslint-disable no-console */
import { getTagged } from '@vivotek/lib-utility/logger';
import NaluParser from './NaluParser';

const Log = getTagged('rtsp-protocol:nalu-assembler');

class NaluAssembler {
  constructor() {
    this.naluParser = new NaluParser();
  }

  onNALUFragment(rtp) {
    const rawData = rtp.getPayload();
    const dts = rtp.getTimestampMS();
    const applicationData = rtp.getApplicationData();

    const fragment = {
      rawData,
      dts,
      applicationData
    };

    if (rtp.encodingName === 'H264') {
      return this.naluParser.parseFragment(fragment);
    }
    Log.error('RTP encoding type is not supported.');
    return null;
  }
}

export default NaluAssembler;
