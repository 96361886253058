export default Object.freeze({
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
  OPTIONS: 'OPTIONS',
  SETUP: 'SETUP',
  ANNOUNCE: 'ANNOUNCE',
  DESCRIBE: 'DESCRIBE',
  TEARDOWN: 'TEARDOWN',
  GET_PARAMETER: 'GET_PARAMETER',
  SET_PARAMETER: 'SET_PARAMETER',
  CHANGE: 'CHANGE',
  REDIRECT: 'REDIRECT',
});
