const getters = {
  systemTime(state) {
    return state.timeInfo.timestamp;
  },
  systemTimezone(state) {
    return state.timeInfo.timezone;
  },
  systemCapability(state) {
    return state.capability;
  },
  hasLiveviewFeature(state) {
    return state.configure.SUPPORT_LIVEVIEW_ON_WEB?.toLowerCase() !== 'false';
  },
  isSupportVortex(state) {
    return state.configure.SUPPORT_VORTEX?.toLowerCase() !== 'false';
  }
};

export default getters;
