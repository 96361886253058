export default class TaskManager {
  constructor(lazy = false, video = null) {
    this.lazy = lazy;
    if (video) {
      this.video = video;
    }
  }

  queue(func) {
    if (!this.lazy) {
      if (this.video && 'requestVideoFrameCallback' in this.video) {
        this.video.requestVideoFrameCallback(func);
      } else if (this.video) {
        this.rafId = requestAnimationFrame(func);
      }
    } else {
      this.icId = requestIdleCallback(func);
    }
  }

  stop() {
    cancelAnimationFrame(this.rafId);
    cancelIdleCallback(this.icId);
  }

  destroy() {
    delete this.video;
  }
}
