<template>
  <div
    class="control-panel-pip"
    @mousewheel.prevent="onMouseWheel($event)"
  >
    <canvas ref="currentViewCell" />
    <svg
      width="100%"
      height="100%"
      class="mask"
    >
      <defs>
        <mask id="pip-hole">
          <rect
            :width="`${viewcellWidth}px`"
            :height="`${viewcellHeight}px`"
            fill="white"
            opacity="0.4"
          />
          <rect
            :x="pipWindow.x"
            :y="pipWindow.y"
            :width="pipWindow.width"
            :height="pipWindow.height"
            fill="black"
          />
        </mask>
      </defs>
      <rect
        :width="`${viewcellWidth}px`"
        :height="`${viewcellHeight}px`"
        mask="url(#pip-hole)"
      />
    </svg>
    <vueDraggableResizable
      v-if="showDraggable"
      ref="draggable"
      :active="true"
      :parent="true"
      :x="pipWindow.x"
      :y="pipWindow.y"
      :w="pipWindow.width"
      :h="pipWindow.height"
      :lockAspectRatio="true"
      :key="value"
      :minWidth="minWidth"
      :minHeight="minHeight"
      :handles="['br']"
      @resizestop="resizestop"
      @dragstop="onDragStop"
      @dragging="dragging"
      @resizing="resizing"
    >
      <div slot="br">
        <svg-icon
          iconClass="status_pip_resize"
          size="20px"
        />
      </div>
    </vueDraggableResizable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import vueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import ThreadableCanvas from '@vivotek/threadable-canvas';

export default {
  name: 'ControlControlPanelPIP',
  components: {
    vueDraggableResizable
  },
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      maxZoomScale: 4,
      viewcell: null,
      minWidth: 0,
      minHeight: 0,
      minScale: 100,
      pipWindow: {
        width: 282,
        height: 212,
        x: 0,
        y: 0
      },
      hole: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
      },
      viewcellHeight: 0,
      viewcellWidth: 0,
      showDraggable: false,
    };
  },
  computed: {
    ...mapGetters('scene', [
      'activeView',
    ]),
    activePipInfo() {
      return this.activeView.pipInfo;
    },
    draggable() {
      return this.$refs.draggable;
    },
    pipInfo() {
      return {
        x: this.pipWindow.x / this.$refs.currentViewCell.offsetWidth,
        y: this.pipWindow.y / this.$refs.currentViewCell.offsetHeight,
        width: Math.min(this.pipWindow.width / this.$refs.currentViewCell.offsetWidth, 1),
        height: Math.min(this.pipWindow.height / this.$refs.currentViewCell.offsetHeight, 1)
      };
    }
  },
  methods: {
    savePipInfo(info) {
      this.activeView.pipInfo = info;
    },
    createPipViewcell(viewcell) {
      if (this.viewcell) {
        this.viewcell.destroy();
      }

      this.viewcell = new ThreadableCanvas({
        source: viewcell.getPlayer(),
        server: ThreadableCanvas.SERVER_TYPE.PIP,
        destination: this.$refs.currentViewCell,
        lazyMode: false
      });

      this.initialize();
    },
    onDragStop(x, y) {
      this.pipWindow.x = x;
      this.pipWindow.y = y;
    },
    resizestop(x, y, width, height) {
      if (this.$refs.currentViewCell.offsetWidth / width >= this.maxZoomScale) {
        this.$emit(
          'input',
          400
        );
        return;
      }

      this.pipWindow.x = x;
      this.pipWindow.y = y;
      this.pipWindow.width = width;
      this.pipWindow.height = height;
      this.$emit(
        'input',
        Math.round((this.$refs.currentViewCell.offsetWidth / width) * 100)
      );
    },
    onMouseWheel(ev) {
      if (ev.wheelDelta > 0) {
        this.$emit(
          'input', this.value + 20 >= 400 ? 400 : this.value + 20
        );
      }
      if (ev.wheelDelta < 0) {
        this.$emit(
          'input', this.value - 20 <= 100 ? 100 : this.value - 20
        );
      }
    },
    zoom(value) {
      let x,
        y;
      const { currentViewCell } = this.$refs;

      const width = currentViewCell.offsetWidth / (value / 100);
      const height = currentViewCell.offsetHeight / (value / 100);

      if (this.pipWindow.x + width <= currentViewCell.offsetWidth) {
        x = this.pipWindow.x;
      } else {
        x = (currentViewCell.offsetWidth - width) / 2;
      }

      if (
        this.pipWindow.y + height
        <= currentViewCell.offsetHeight
      ) {
        y = this.pipWindow.y;
      } else {
        y = (currentViewCell.offsetHeight - height) / 2;
      }

      this.pipWindow = {
        width,
        height,
        x,
        y
      };
    },
    setClipPosition() {
      const { draggable } = this.$refs;
      const {
        top, left,
      } = draggable;

      this.pipWindow.x = left;
      this.pipWindow.y = top;
    },
    resizing(x, y, width, height) {
      this.pipWindow.width = width;
      this.pipWindow.height = height;
    },
    dragging(x, y) {
      const { draggable, currentViewCell } = this.$refs;
      const { width, height } = draggable;

      const top = y;
      const left = x;
      const bottom = currentViewCell.offsetHeight - height - y;
      const right = currentViewCell.offsetWidth - width - x;

      this.setClipPosition(top, bottom, left, right);
    },
    initialize() {
      const { offsetWidth, offsetHeight } = this.$refs.currentViewCell;

      if (!offsetWidth || !offsetWidth) {
        return;
      }

      this.viewcellWidth = offsetWidth;
      this.viewcellHeight = offsetHeight;
      this.minWidth = this.viewcellWidth / this.maxZoomScale;
      this.minHeight = this.viewcellHeight / this.maxZoomScale;

      if (this.value === 100) {
        this.pipWindow.x = 0;
        this.pipWindow.y = 0;
        this.pipWindow.width = this.viewcellWidth;
        this.pipWindow.height = this.viewcellHeight;
      } else {
        const {
          x, y, width, height
        } = this.activePipInfo;

        this.pipWindow.x = x * offsetWidth;
        this.pipWindow.y = y * offsetHeight;
        this.pipWindow.width = width * offsetWidth;
        this.pipWindow.height = height * offsetHeight;
      }

      this.showDraggable = true;
    },
    playerObserver() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }

      const vueAttr = '__vue__';
      const target = document.querySelector(`#viewcell-${this.activeView.encoder_id}`)?.[vueAttr]?.viewcell;
      if (target) {
        this.createPipViewcell(target);
        return;
      }
      // create an observer instance
      this.observer = new MutationObserver((mutations) => {
        mutations.some((mutation) => this.createPipViewcell(target));
      });

      const config = { attributes: true, childList: true, characterData: true };
      this.observer.observe(target, config);
    },
  },
  watch: {
    value(value) {
      this.zoom(value);
    },
    activeView() {
      this.playerObserver();
    }
  },
  mounted() {
    const vueAttr = '__vue__';
    const target = document.querySelector(`#viewcell-${this.activeView.encoder_id}`)?.[vueAttr]?.viewcell;
    if (!target) {
      return;
    }
    this.createPipViewcell(target);

    this.resizeObs = new ResizeObserver(([entry]) => {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      // resize only after dragging ended
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.initialize();
      }, 100);
    });
    this.resizeObs.observe(this.$refs.currentViewCell);
  },
  beforeDestroy() {
    if (this.viewcell) {
      this.viewcell.destroy();
      this.viewcell = null;
    }

    this.resizeObs.disconnect();
    this.resizeObs = null;
  },
  updated() {
    this.setClipPosition();
    this.savePipInfo(this.pipInfo);
  }
};
</script>

<style lang="less" scoped>
.control-panel-pip {
  width: 100%;
  position: relative;
  .mask {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    pointer-events: none;
    max-height: 100%;
    max-width: 100%;
  }
  > canvas {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    display: block;
  }
  .draggable {
    border: 3px double rgb(0, 0, 0);
    &.active {
      cursor: all-scroll;
      ::v-deep .handle {
        width: 20px;
        height: 20px;
        background: unset;
        border: none;
      }
      ::v-deep .handle-br {
        bottom: -2px;
        right: -2px;
        .svg-icon {
          cursor: se-resize;
        }
      }
    }
  }
}
</style>
