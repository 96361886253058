import { getTagged } from '@vivotek/lib-utility/logger';
import VcaCanvas from './VcaCanvas';
import CropCanvas from './CropCanvas';
import StretchCanvas from './StretchCanvas';
import DragLine from './DragLine';
import $postMessage from './postMessage';

const Log = getTagged('threading-canvas:usual-image-server');

/**
 * UsualImageServer is composed by 3 canvas handlers.
 * CropCanvas takes the config of PIP;
 * StretchCanvas takes the config of stretch;
 * VcaCanvas takes the config/metadata of vca.
 */
export default class UsualImageServer {
  constructor({ destination }, _, client) {
    const ENVIRONMENT_IS_WORKER = typeof importScripts === 'function';
    // source will come from render command
    this.source = null;
    this.destination = destination;
    this.client = client;
    if (this.destination) {
      this.finalContext = this.destination.getContext('2d');
      this.destination.width = 3840;
      this.destination.height = 2160;
    }
    this.targetWidth = 0;
    this.targetHeight = 0;
    this.vcaCanvas = new VcaCanvas({
      useOffscreenCanvas: ENVIRONMENT_IS_WORKER,
    });
    this.cropCanvas = new CropCanvas({
      useOffscreenCanvas: ENVIRONMENT_IS_WORKER,
    });
    this.stretchCanvas = new StretchCanvas({
      useOffscreenCanvas: ENVIRONMENT_IS_WORKER,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  ready() {
    return Promise.resolve();
  }

  send(data) {
    $postMessage({
      dispatch: 'message',
      event: {
        type: 'message',
        data,
      },
    }, this.client);
  }

  /**
   * If server is in main-thread, the client will call postMessage to
   * communicate to us.
   */
  postMessage(data) {
    $postMessage(data, this);
  }

  command(command, ...args) {
    this[command](...args);
  }

  /**
   * This is to align worker's terminate() method.
   */
  terminate() {
    this.stretchCanvas.destroy();
    this.cropCanvas.destroy();
    this.vcaCanvas.destroy();
    delete this.stretchCanvas;
    delete this.cropCanvas;
    delete this.vcaCanvas;
    delete this.finalContext;
    delete this.source;
    delete this.destination;
  }

  resize(width, height) {
    this.targetWidth = width;
    this.targetHeight = height;
    // only stretch canvas cares the final target resize
    this.stretchCanvas.resize(width, height);
  }

  /**
   * The command is coming from the image client and maybe
   * passed by the worker middleware if we are multi-threaded.
   * We will render each canvas handler in certain order
   * and then the final context for final image.
   * @param {Object} imageBitmap
   * @param {Number} width
   * @param {Number} height
   */
  render(imageBitmap, width, height) {
    this.vcaCanvas.render(imageBitmap, width, height);
    this.cropCanvas.render(this.vcaCanvas.destination, width, height);
    this.destination.width = width;
    this.destination.height = height;
    this.finalContext.clearRect(0, 0, width, height);
    this.finalContext.drawImage(this.cropCanvas.destination, 0, 0, width, height);
    if (this.dragging) {
      this.dragLine = new DragLine({
        start: this.dragStart,
        current: this.dragging,
        width: this.targetWidth,
        height: this.targetHeight,
      });
      this.dragLine.draw(this.finalContext, width, height);
    }
  }

  // We don't have real mouse event if running on off-thread.
  // the worker middleware will bypass event from main-thread to us via handleEvent.
  handleEvent(evt) {
    if (this[`_handle_${evt.type}`]) {
      this[`_handle_${evt.type}`](evt);
    }
  }

  config(key, value) {
    this[key] = value;
    Log.debug('Got config', key, value);
    if (this[`watch_${key}`]) {
      this[`watch_${key}`](value);
    }
  }

  watch_pip(pip) {
    this.cropCanvas.pip = pip;
    this.send({
      type: 'pip',
      data: this.cropCanvas.pip,
    });
  }

  watch_showVCA(value) {
    this.vcaCanvas.enable = value;
  }

  watch_stretch(stretch) {
    this.stretchCanvas.enable = stretch;
  }

  watch_vcaConfig(config) {
    this.vcaCanvas.displayConfig = {
      ...this.vcaCanvas.displayConfig,
      ...config,
    };
  }

  watch_projection(projection) {
    this.vcaCanvas.vcaProject = projection;
  }

  watch_rule(rule) {
    this.vcaCanvas.rules = rule;
  }

  watch_metadata(metadata) {
    this.vcaCanvas.metadata = metadata;
  }

  watch_rectangle(data) {
    this.vcaCanvas.addRectangle(data);
  }
}
