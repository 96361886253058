import { getTagged } from '@vivotek/lib-utility/logger';
import { saveAs } from 'file-saver';
import EventEmitter from 'events';
import MouseEventDispatcher from './MouseEventDispatcher';

const Log = getTagged('threading-canvas:image-client');

/**
 * The design purpose is to have a general image client for
 * different kind of image servers,
 * so image client won't take detail of image handling but
 * just pass config/command to the server.
 *
 * Currently, we need to bypass the image server type
 * to the remote worker middleware,
 * so serverType is necessary.
 *
 * preprocessor is for PTZ camera, which should includes
 * the API service for joystick action.
 *
 * ssmuxInstance is optional; if we are in main-thread,
 * we don't want to create ssmux instance again because
 * liveview/playback already created it.
 */

export default class ImageClient extends EventEmitter {
  constructor({
    source,
    destination,
    Main,
    Worker,
    serverType = 0,
    useOffscreenIfPossible = true,
    forceRenderIfPaused = true,
    preprocessor = null,
  }, ssmuxInstance) {
    super();

    this.settings = {};
    this.source = source;
    this.destination = destination || document.createElement('canvas');
    this.screenshotType = 'png';
    this.screenshotName = 'screenshot';
    if (preprocessor) {
      this.preprocessor = preprocessor;
    }
    this.dispatcher = new MouseEventDispatcher(this.destination, this);
    this.Main = Main;
    this.Worker = Worker;
    this.ssmuxInstance = ssmuxInstance;
    this.serverType = serverType;
    this.useOffscreenIfPossible = useOffscreenIfPossible;
    this.forceRenderIfPaused = forceRenderIfPaused;
    this.setupServer();
  }

  setupServer() {
    if (!this.Main && !this.Worker) {
      return;
    }
    this.serverReady = false;
    if (!this.destination.transferControlToOffscreen || this.useOffscreenIfPossible === false) {
      this.server = new this.Main({ destination: this.destination }, this.ssmuxInstance, this);
      this.server.ready().then(() => {
        this._handle_message();
      });
    } else {
      const worker = new this.Worker();
      this.server = worker;
      this.server.addEventListener('message', this);
      const offscreen = this.destination.transferControlToOffscreen();
      this.server.postMessage({ offscreen, serverType: this.serverType }, [offscreen]);
    }
  }

  handleEvent(evt) {
    if (this[`_handle_${evt.type}`]) {
      this[`_handle_${evt.type}`](evt);
    }
  }

  _handle_message(evt) {
    // we need worker to tell us it's ready so we can pass parameters to it.
    if (!this.serverReady) {
      this.serverReady = true;
      Object.entries(this.settings).forEach(([key, value]) => {
        this.config(key, value);
      });
      if (this.width && this.height) {
        this.resize(this.width, this.height);
      }
    }

    if (evt && evt.data) {
      const {
        type, data
      } = evt.data;

      this.emit(type, data);

      this.settings[type] = data;

      if (this.forceRenderIfPaused && (type === 'pip' || type === 'preset')) {
        this.forceRender();
      }
    }
  }

  forceRender() {
    if (!this.source.paused) {
      return;
    }
    this.render(this.source, this.source.videoWidth, this.source.videoHeight);
  }

  destroy() {
    this.removeAllListeners();
    this.dispatcher.destroy();
    this.server.terminate();
    this.server.removeEventListener && this.server.removeEventListener('message', this);
  }

  resize(width, height) {
    this.width = width;
    this.height = height;
    if (this.preprocessor) {
      this.preprocessor.resize(width, height);
    }

    if (!this.serverReady) {
      Log.debug('Image server is not ready.');
      return;
    }

    Log.debug('Commanding resize', width, height);
    this.command('resize', width, height);
  }

  /**
   * Change configuration of the server;
   * server may not response if the config key is not valid to it.
   * We devide the behavior into 2 parts: config & command
   * config is used to ask the server to change the value of the specific key.
   * command is used to ask the server to do some actions with maybe more than
   * 1 argument; it might not be key-value based.
   * @param {String} key
   * @param {*} value
   * @returns
   */
  config(key, value) {
    // Metadata could be triggered very often.
    // We don't want to post it every time.
    this.settings[key] = value;
    if (!this.serverReady) {
      Log.debug('Image server is not ready', this.settings);
      return;
    }
    Log.debug('Sending config', key, value);

    this.server.postMessage({
      config: key,
      value,
    });

    this.preprocessor && this.preprocessor.setHandlerEnable(key, !!value);
  }

  command(command, ...args) {
    this.server.postMessage({
      command,
      arguments: [...args],
    });
  }

  /**
   * This is called from MouseEventDispatcher, which uses our destination
   * @param {Object} event
   * @returns
   */
  dispatch(event) {
    // XXX: event cannot be cloned to worker.
    // so we just pick what we need here.
    // if you want to dispatch more detail, add more field here.

    // XXX: If server is fisheye, it will handle mouse event on its own.
    // XXX: If server is usual len, we need the parent to pass a preprocessor
    // to us, which is having the joystick API function for PTZ camera.
    // we don't held the detail of how to write the API service on our own.
    if (this.preprocessor && this.preprocessor.handleEvent(event, this)) {
      Log.debug(event, ' is handled.');
      return;
    }
    Log.debug('Sending event', event);
    this.server.postMessage({
      dispatch: event.type,
      event: {
        type: event.type,
        offsetX: event.offsetX,
        offsetY: event.offsetY,
        deltaX: event.deltaX,
        deltaY: event.deltaY,
      },
    });
  }

  /**
   * render request from outside which is coming from the task manager
   * @param {Object} imageBitmap
   * @param {Number} width
   * @param {Number} height
   * @returns
   */
  render(imageBitmap, width, height) {
    if (!imageBitmap) {
      return;
    }
    this.preprocessor && this.preprocessor.render(width, height);
    this.server.postMessage(
      {
        command: 'render',
        arguments: [imageBitmap, width, height],
      },
      [imageBitmap]
    );
  }

  /**
   * Take snapshot from given source or destination
   * @param {Boolean} useSource - use source instead of destination
   * @param {Boolean} download - auto download via FileSaver
   * @returns
   */
  async snapshot(useSource = true, download = true) {
    const screenshotType = `image/${this.screenshotType}`;
    const screenshotName = `${this.screenshotName}.${this.screenshotType}`;
    const canvasEl = document.createElement('canvas');
    const canvasTx = canvasEl.getContext('2d');
    let source;

    if (useSource) {
      source = this.source;
      // set image's width/height by streaming's resolution
      canvasEl.width = this.source.videoWidth || this.source.width;
      canvasEl.height = this.source.videoHeight || this.source.height;
    } else {
      /**
       * In offscreen mode, we can still use the distestination canvas
       * to be source of another canvas.
       */
      source = this.destination;
      // set image's width/height by canvas element's clientWidth/clientHeight
      canvasEl.width = this.destination.width;
      canvasEl.height = this.destination.height;
    }

    canvasTx.drawImage(source, 0, 0, canvasEl.width, canvasEl.height);
    return new Promise((resolve) => {
      canvasEl.toBlob((blob) => {
        if (download) {
          saveAs(blob, screenshotName, screenshotType);
        }
        resolve(blob);
      });
    });
  }
}
