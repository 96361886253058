import detectedBrowser from '@vivotek/lib-utility/detectedBrowser';
import { ab2str } from '@vivotek/lib-utility/convert_arraybuffer_string';
import { combineBuffer } from '@vivotek/lib-utility/byteArray';
import { getTagged, Logger } from '@vivotek/lib-utility/logger';

export const { isIE } = detectedBrowser;
export const { isSafari } = detectedBrowser;
export const { isFirefox } = detectedBrowser;

const Log = getTagged('rtsp-protocol', Logger.DEBUG);

export const parseBinaryToJSON = function parseBinaryToJSON(notify) {
  let res;
  let arr;

  try {
    if (isIE) {
      arr = new Uint8Array(notify.data.toArray()).slice(4);
      res = JSON.parse(ab2str(arr));
    } else {
      res = JSON.parse(ab2str(notify));
    }
  } catch (e) {
    Log.warn('Failed to parse notify.', e);
  }

  return res;
};

export const readUInt16BE = function readUInt16BE(bArray, index, length) {
  let val = '';
  const arrayLength = length === undefined ? 2 : length;

  for (let i = 0; i < arrayLength; i += 1) {
    val += (`0${bArray[index + i].toString(16)}`)?.substr(-2);
  }
  return parseInt(val, 16);
};

export const checkStringInHead = function checkStringInHead(arrayData, str) {
  return [].map.call(str, (val) => val.charCodeAt(0)).every((val, i) => arrayData[i] === val);
};

export const checkIsRTPData = function checkBinaryFirstIs(bArray, value = 0x24) {
  return bArray[0] === value && (bArray[1] >= 0 || bArray[1] < 256);
};

export const buildSdpMessage = function buildSdpMessage(cmd, host, params = {}, payload = null) {
  let requestString = `${cmd} ${host} ${this.version}\r\n`;
  Object.keys(params).forEach((param) => {
    requestString += `${param}: ${params[param]}\r\n`;
  });
  // TODO: binary payload
  if (payload) {
    requestString += `Content-Length: ${payload.length}\r\n`;
  }
  requestString += '\r\n';
  if (payload) {
    requestString += payload;
  }
  return requestString;
};

export const combinePacketArray = function combinePacketArray(packets) {
  const combinePackets = packets.reduce((prev, curr) => {
    if (!prev) {
      return curr;
    }
    return combineBuffer(prev, curr);
  // eslint-disable-next-line no-use-before-define
  }, null);
  return combinePackets;
};

export const appendBuffer = function appendBuffer(buffer1, buffer2) {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
};
