/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import sdpTransform from 'sdp-transform';

class SDPParser {
  constructor() {
    this.media = {};
    this.mediaBlockList = [];
  }

  parse(content) {
    if (/m=video\r\n/.test(content)) {
      // amending sdp m block for camera because it is poor.
      // sample: m=video 51744 RTP/AVP 126
      content = content.replace(/m=video\r\n/, 'm=video 0 RTP/AVP 1\r\n');
    }
    const res = sdpTransform.parse(content);
    this.parsed = res;
    this.raw = content;
    // convert array of media session to object to back-compat.
    // XXX: Refine "media" structure later.
    this.media = Object.fromEntries(new Map(res.media.map((m, i) => {
      m.rtpmap = Object.fromEntries(new Map(m.rtp.map((r) => {
        r.name = r.codec.toUpperCase();
        r.encodingName = r.name;
        r.clock = r.rate;
        r.encodingParameters = r.encoding;
        return [r.payload, r];
      })));
      if (m.control) {
        m.trackID = sdpTransform.parseParams(m.control).trackID;
      }
      m.proto = m.protocol;
      if (typeof m.payloads === 'string') {
        m.format = m.payloads.split(' ').map((fmt) => parseInt(fmt, 10));
      } else if (!Number.isNaN(m.payloads)) {
        m.format = [m.payloads];
      }
      [m.payloadType] = m.format;
      m.mode = m.direction;
      if (m.fmtp.length) {
        m.fmtp = sdpTransform.parseParams(m.fmtp[0].config);
      }
      return [m.type, m];
    })));
    this.mediaBlockList = Array.from(Object.values(this.media));
    return this.mediaBlockList;
  }

  hasMedia(mediaType) {
    return this.media[mediaType] !== undefined;
  }

  getMediaBlockByPayloadType(payloadType) {
    return this.mediaBlockList.filter(
      (mediaBlock) => mediaBlock.format.indexOf(payloadType) >= 0
    )[0];
  }
}

export default SDPParser;
