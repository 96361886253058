import EventEmitter from 'events';
import { getTagged, Logger } from '@vivotek/lib-utility/logger';
import RtspParser from '../models/RtspParser';
import PacketManager from '../models/PacketManager';
import SdpParser from '../models/SdpParser';
import RTPFactory from './utils/RtpFactory';

const Log = getTagged('rtsp-protocol', Logger.DEBUG);

class RtspProtocol extends EventEmitter {
  constructor(channel, options) {
    super();

    this.messageMap = {};
    this.channel = channel;

    this.sdpParser = new SdpParser();
    // XXX: Somehow RTP factory depends on sdpParser.
    // If not, we can move sdpParser into RtspParser.
    // Maybe let rtpFactory to generate its own sdpParser.
    this.rtpFactory = new RTPFactory(this.sdpParser);
    this.packetManager = new PacketManager(this);
    this.rtspParser = new RtspParser(this);
    this.channel.on('message', this._handle_message);
    this.channel.on('close', this._handle_close);
  }

  supportSeek() {
    return this.rtspParser.rtspServer.indexOf('Streaming Server') < 0;
  }

  isRTSP() {
    return this.rtspParser.isRTSP();
  }

  isRTSPLike() {
    return this.rtspParser.isRTSPLike();
  }

  useRTSP() {
    this.rtspParser.useRTSP();
    return this;
  }

  useRTSPLike() {
    this.rtspParser.useRTSPLike();
    return this;
  }

  destroy() {
    if (this.channel) {
      this.channel.off('message', this._handle_message);
      this.channel.off('close', this._handle_close);
      this.channel = null;
    }

    this.removeMessageMap();
    this.packetManager.destroy();

    this.sdpParser = null;
    this.rtpFactory = null;
  }

  _handle_message = (message) => {
    // whenever got message from websocket(or webrtc)
    // parse it first.
    // rtspParser will call handlePacket of us if the message is a packet.
    this.rtspParser.parse(message);
  }

  handleRtspMessages(messages) {
    messages.forEach((response) => {
      this.receiveRtspResponse(response);
    });
  }

  receiveRtspResponse(response) {
    if (!this.messageMap[response.id]) {
      return;
    }

    this.messageMap[response.id].response(response);
    delete this.messageMap[response.id];
  }

  _handle_close = (ev) => {
    Log.error('RTSPChannel closed.');
    this.removeMessageMap();
    this.emit('close', ev);
  }

  handlePacket = (packet /* , message */) => this.packetManager.handlePacket(packet)

  sendRtspCommand(message, timeout) {
    if (this.channel.readyState !== 'open' && this.channel.readyState !== 1) { // 'open' for datachannel, 1 for websocket
      return Promise.reject(new Error('Data channel is not open'));
    }
    const request = this.rtspParser.createRequest(message, timeout);
    this.messageMap[request.id] = request;
    return request.send(this.channel);
  }

  sendOptions(url) {
    return this.sendRtspCommand({
      url,
      method: RtspParser.METHODS.OPTIONS,
    })
      .then((response) => {
        this.rtspMethods = response.methods;
      });
  }

  sendDescribe(url, hasBackchannel) {
    return this.sendRtspCommand({
      url,
      method: RtspParser.METHODS.DESCRIBE,
      headers: {
        ...(hasBackchannel ? { Require: 'www.onvif.org/ver20/backchannel' } : {}),
      },
    })
      .then((response) => {
        this.tracks = response.tracks;
        this.tracks.forEach((track) => this.packetManager.initializeTrackMap(track));
        return Promise.resolve([response.status, this.tracks]);
      });
  }

  sendSetup(url) {
    return this.sendRtspCommand({
      url,
      method: RtspParser.METHODS.SETUP,
    })
      .then((response) => {
        if (response.headers.session) {
          this.session = response.headers.session;
        }
        return Promise.resolve([response.status, this.session]);
      });
  }

  sendPlay(url, options = {}) {
    return this.sendRtspCommand({
      method: RtspParser.METHODS.PLAY,
      url,
      headers: {
        Session: this.rtspParser.session,
        ...options,
      },
    }).then((response) => Promise.resolve(response.headers.range));
  }

  sendPause(url, options = {}) {
    return this.sendRtspCommand({
      url,
      method: RtspParser.METHODS.PAUSE,
      headers: {
        Session: this.rtspParser.session,
        ...options,
      },
    });
  }

  sendTeardown(url) {
    return this.sendRtspCommand({
      method: RtspParser.METHODS.TEARDOWN,
      url,
      headers: {
        Session: this.rtspParser.session,
      },
    }, 1 * 1000);
  }

  removeMessageMap() {
    if (!this.messageMap) {
      return;
    }
    Object.keys(this.messageMap).forEach((id) => {
      this.messageMap[id].destroy();
      delete this.messageMap[id];
    });
  }
}

export default RtspProtocol;
